/**
 * It will return like this.
 * {
 *   propertyValue1: [{}, {}],
 *   propertyValue2: [{}, {}]
 * }
 */
export function groupBy(objectArray, property) {
  return objectArray.reduce(function (acc, obj) {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}