import {createUrlParametersFromObject} from "../../../utils/httpUtils";

export default function ProductService({httpClient, authenticationService}) {
  return {
    fetchWaxProducts,
    fetchWaxProductsByIds,
    fetchProductDetails,
    searchProductsByText,
    searchWaxProducts,
    fetchWaxSubTypes
  };

  // Admin only
  async function fetchWaxProducts() {
    const response = await httpClient.get(
       '/product/wax',
       {headers: {'Authorization': `Bearer ${authenticationService.getToken()}`}}
    );
    return response.data
  }


  /**
   * @Deprecated This is not restful. Condider createing something like GetWaxForReport /report/products
   * @param ids
   * @returns {Promise<*>}
   */
  async function fetchWaxProductsByIds(ids) {
    const response = await httpClient.get(
       `/product/wax?waxIds=${ids.join(',')}`,
    );
    return response.data
  }

  async function fetchProductDetails(id) {
    const response = await httpClient.get(
       `/product/${id}/details`,
    );
    return response.data
  }

  async function searchProductsByText(query) {
    const response = await httpClient.get(
       `/product/wax?query=${query}`,
       {headers: {'Authorization': `Bearer ${authenticationService.getToken()}`}}
    );
    return response.data
  }

  async function searchWaxProducts(query) {
    const maybeAuthToken = authenticationService.getToken();
    const maybeHeaders = maybeAuthToken ? {headers: {'Authorization': `Bearer ${authenticationService.getToken()}`}} : null
    
    const response = await httpClient.get(
       `/product/wax/recommend?${createUrlParametersFromObject(query)}`,
       maybeHeaders
    );
    return response.data
  }

  function fetchWaxSubTypes() {
    return httpClient.get(`/product/wax/type/sub`,)
       .then(response => response.data)
  }
}
