import React, {Component} from 'react';
import {connect} from "react-redux";

class WaitForCriticalDataToLoad extends Component {
  render() {

    const snowTypesLoaded = this.props.condition.snowTypes.length > 0
    const waxSubTypesLoaded = this.props.product.waxSubTypes.length > 0

    if (snowTypesLoaded && waxSubTypesLoaded) {
      return this.props.children;
    } else return <div/>
  }
}

const mapStateToProps = state => ({
  condition: state.condition,
  product: state.product
});


export default connect(mapStateToProps)(WaitForCriticalDataToLoad);