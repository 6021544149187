import React, {Component} from 'react';
import RecommendationFlow from "../search/flow/RecommendationFlow";
import Page from "./Page";
import Header1 from "../components/helperComponents/text/Header1";
import LinkedBulletList from "../components/helperComponents/lists/LinkedBulletList";
import Header4 from "../components/helperComponents/text/Header4";
import skisAndMountains3 from '../../assets/minifyed-illustration.svg'


class LandingPage extends Component {
  render() {
    return (
       <Page disableNavigationField={true}>
         <div className="landing-page-container">
           <div className="landing-page-content">
             <div className="landing-page-recommendation">
               <div className="landing-page-header-container">
                 <Header1 title="Hitta den perfekta vallningen."/>
               </div>
               <div>
                 <RecommendationFlow {...this.props}/>
               </div>
             </div>
           </div>
           <div className="landing-page-information-container">
             <div className="landing-page-image-container">
               <img className="landing-page-image" src={skisAndMountains3} alt="How it works"/>
             </div>
             <div className="landing-page-information">
               <div className="landing-page-information-content-wrapper">
                 <div className="landing-page-information-content">
                   <Header4 text="Såhär fungerar det..."/>
                   <LinkedBulletList
                      items={[
                        "Hitta vallningsförslag hos Vallaloggen.",
                        "Valla dina skidor och åk.",
                        "Rapportera hur det gick och gör vallaloggen ännu smartare!"
                      ]}/>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </Page>
    );
  }

  redirectToSearchResults() {
    this.props.history.push('/reports/search');
  }

  goToHelpPage() {
    this.props.history.push('/help-vallaloggen');
  }

  goToAboutPage() {
    this.props.history.push('/about');
  }

  redirectToWaxSearch() {
    this.props.history.push('/products/search');
  }
}

export default LandingPage;