import React, {Component} from 'react';
import Input from "../Input";
import {truthyOrZero} from "../../../../../utils/intUtils";

class TemperatureInput extends Component {

  state = {
    temperature: ''
  }

  componentDidMount() {
    if (truthyOrZero(this.props.initialValue)) {
      this.setState({temperature: this.props.initialValue});
    }
  }

  setTemperature(temperature) {
    const parsedTemp = parseInt(temperature)
    if (temperature === '') {
      this.setState({temperature: ''});
      this.props.onTemperatureChange('')
    } else if (temperature === '-') {
      this.setState({temperature: '-'});
    } else if (!isNaN(parsedTemp)) {
      this.setState({temperature: parsedTemp});
      this.props.onTemperatureChange(parsedTemp)
    }
  }

  render() {
    return (
       <div>
         <Input
            label={this.props.label || "Temperatur"}
            value={this.state.temperature}
            type="text"
            icon='thermometer-quarter'
            autoComplete="off"
            placeholder={this.props.placeholder}
            onChange={e => this.setTemperature(e.target.value)}/>
       </div>
    );
  }
}

export default TemperatureInput;
