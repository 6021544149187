import React, {Component, createRef} from 'react';
import {Button, Form, FormField, Select, TextInput} from "grommet";
import ListItem from "./ListItem";
import {connect} from "react-redux";
import {FormRefresh} from "grommet-icons";
import MultiSelect from "../../components/helperComponents/MultiSelect";

class AddWaxComponent extends Component {

  constructor(props) {
    super(props);
    this.nameField = createRef();
  }

  state = {
    waxName: '',
    waxType: this.props.admin.waxTypes[0],
    waxSubType: '',
    id: '',
    brand: '',
    image: 'To be added',
    conditions: [{fromTemperature: '', toTemperature: 0, airHumidity: 0, snowType: ''}],
    snowTypeFilter: []
  };

  componentDidMount() {
    if (!this.props.admin.waxBrands.length) {
      this.props.dispatch(this.props.actions.fetchBrands());
    }
    if (!this.props.product.waxSubTypes.length) {
      this.props.dispatch(this.props.actions.fetchWaxSubTypes());
    }
    if (!this.props.condition.snowTypes.length) {
      this.props.dispatch(this.props.actions.fetchSnowTypes());
    }
    if (!this.props.product.waxProducts.length) {
      this.props.dispatch(this.props.actions.fetchWaxProducts());
    }
    this.nameField.current.focus();
  }

  fetchDataAgain() {
    this.props.dispatch(this.props.actions.fetchBrands());
    this.props.dispatch(this.props.actions.fetchWaxSubTypes());
    this.props.dispatch(this.props.actions.fetchSnowTypes());
    this.props.dispatch(this.props.actions.fetchWaxProducts());
  }

  setWaxName(waxName) {
    this.setState({waxName});
  }

  setBrand(brand) {
    this.setState({brand});
  }

  setSubTypeId(id) {
    this.setState({id});
  }

  setType(waxType) {
    this.setState({waxType})
  }

  setSubType(waxSubType) {
    this.setState({waxSubType});
  }

  submit() {
    const newWax = {
      waxName: this.state.waxName,
      waxType: this.state.waxType,
      waxSubType: this.state.waxSubType,
      id: this.state.id,
      brand: this.state.brand,
      image: this.state.image,
      conditions: this.state.conditions
    };
    this.props.dispatch(this.props.actions.createWax(newWax));
    this.setState({
      waxName: '',
      id: '',
      conditions: [{fromTemperature: '', toTemperature: 0, airHumidity: 0, snowType: ''}]
    });
    this.nameField.current.focus();
  }

  render() {
    const waxTypes = this.props.admin.waxTypes;
    const waxSubTypes = this.props.product.waxSubTypes
       .filter(type => type.waxType === this.state.waxType)
       .map(type => type.waxSubTypeName);
    const waxBrands = this.props.admin.waxBrands;
    const snowTypes = this.props.condition.snowTypes
    const snowTypeNames = this.props.condition.snowTypes.map(s => s.snowType);
    const waxes = this.props.product.waxProducts;
    const isError = JSON.stringify(this.props.admin.waxResult).includes('apiError'); // just for quick convenience
    return (
       <div className="admin-add-container">
         <div>
           <h1>Lägg till valla<FormRefresh onClick={() => this.fetchDataAgain()}/></h1>
           <div className="admin-paper">
             <Form onSubmit={() => this.submit()} className="admin-form">
               <FormField label="Vallanamn" name="waxname">
                 <TextInput
                    ref={this.nameField}
                    value={this.state.waxName}
                    onChange={(e) => this.setWaxName(e.target.value)}
                 />
               </FormField>
               <FormField label="Märke" name="waxbrand">
                 <Select
                    options={waxBrands}
                    value={this.state.brand}
                    onChange={({option}) => this.setBrand(option)}
                 />
               </FormField>
               <FormField label="Vallatyp" name="waxtype">
                 <Select
                    options={waxTypes}
                    value={this.state.waxType}
                    onChange={({option}) => this.setType(option)}
                 />
               </FormField>
               <FormField label="Valla subtyp" name="waxsubtype">
                 <Select
                    options={waxSubTypes}
                    value={this.state.waxSubType}
                    onChange={({option}) => this.setSubType(option)}
                 />
               </FormField>

               {this.state.conditions.map((condition, index) => (
                  <FormField label="Före" key={index} plain="true">
                    <div className="admin-add-wax-condition">
                      <FormField label="Från temperatur">
                        <TextInput
                           placeholder="Lägsta"
                           type="number"
                           value={condition.fromTemperature}
                           onChange={(e) => this.setConditionFromTemp(e.target.value, index)}
                        />
                      </FormField>
                      <FormField label="Till temperatur">
                        <TextInput
                           placeholder="Högsta"
                           type="number"
                           value={condition.toTemperature}
                           onChange={(e) => this.setConditionToTemp(e.target.value, index)}
                        />
                      </FormField>
                      <FormField label="Luftfuktighet">
                        <TextInput
                           type="number"
                           value={condition.airHumidity}
                           onChange={(e) => this.setConditionAirHumidity(e.target.value, index)}
                        />
                      </FormField>
                      <FormField label="Snötyp">
                        <Select
                           options={snowTypeNames}
                           value={condition.snowType}
                           onChange={({option}) => this.setConditionSnowType(option, index)}
                        />
                      </FormField>
                    </div>
                    <FormField>
                      <Button label="Ett till" onClick={() => this.addNewCondition()}/>
                    </FormField>
                  </FormField>
               ))}
               <FormField label="Eventuellt id (UUID)" name="subtypeid">
                 <TextInput
                    value={this.state.id}
                    onChange={(e) => this.setSubTypeId(e.target.value)}
                 />
               </FormField>
               <Button label="Skapa" onClick={() => this.submit()}/>
             </Form>
           </div>
           <div className="admin-paper" style={{backgroundColor: isError ? 'red' : 'white'}}>
             <pre>
               {JSON.stringify(this.props.admin.waxResult, null, 4)}
             </pre>
           </div>
           <h3>Existerande vallor</h3>
           <div>
             <div className="admin-wax-filter">
               <MultiSelect
                  selected={this.state.snowTypeFilter}
                  onSelectedItems={items => this.filterOnSnowType(items)}
                  key={1}
                  options={snowTypes.map(s => ({value: s.id, text: s.snowType}))}
                  placeholder="Ange en snötyp för bästa resultat"
               />
               <h3>{this.getWaxFiltered().length}</h3>
             </div>
             {waxes.length && this.getWaxFiltered().reverse().map(wax =>
                <ListItem
                   key={wax.id}
                   title={wax.brand + ' ' + wax.waxName}
                   details={wax}
                />
             )}
           </div>
         </div>
       </div>
    );
  }

  setConditionFromTemp(fromTemperature, index) {
    const conditions = [...this.state.conditions];
    const newTemp = parseInt(fromTemperature);
    if (!isNaN(newTemp)) {
      conditions[index].fromTemperature = newTemp;
      this.setState({conditions});
    } else {
      conditions[index].fromTemperature = '';
      this.setState({conditions});
    }
  }

  setConditionToTemp(toTemperature, index) {
    const conditions = [...this.state.conditions];
    const newTemp = parseInt(toTemperature);
    if (!isNaN(newTemp)) {
      conditions[index].toTemperature = newTemp;
      this.setState({conditions});
    } else {
      conditions[index].toTemperature = '';
      this.setState({conditions});
    }

  }

  setConditionAirHumidity(airHumidity, index) {
    const conditions = [...this.state.conditions];
    const newAirHumidity = parseInt(airHumidity);
    if (!isNaN(newAirHumidity)) {
      conditions[index].airHumidity = newAirHumidity;
      this.setState({conditions});
    } else {
      conditions[index].airHumidity = '';
      this.setState({conditions});
    }
  }

  setConditionSnowType(snowType, index) {
    const conditions = [...this.state.conditions];
    conditions[index].snowType = snowType;
    this.setState({conditions});
  }

  addNewCondition() {
    const conditions = [...this.state.conditions];
    const lastCondition = conditions[conditions.length - 1];
    conditions.push({
      fromTemperature: lastCondition.fromTemperature,
      toTemperature: lastCondition.toTemperature,
      airHumidity: lastCondition.airHumidity,
      snowType: lastCondition.snowType
    });
    this.setState({conditions});
  }

  filterOnSnowType(snowTypes) {
    this.setState({snowTypeFilter: snowTypes.map(s => ({id: s.value, snowType: s.text}))});
  }

  getWaxFiltered() {
    return this.state.snowTypeFilter.length
       ? this.props.product.waxProducts.filter(w => w.conditions.some(c => this.state.snowTypeFilter.map(sf => sf.snowType).includes(c.snowType)))
       : this.props.product.waxProducts;
  }
}

const mapStateToProps = state => ({
  admin: state.admin,
  condition: state.condition,
  product: state.product
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    actions: {...stateProps.admin.actions, ...stateProps.condition.actions, ...stateProps.product.actions},
    ...dispatchProps,
    ...ownProps
  }
};
export default connect(mapStateToProps, null, mergeProps)(AddWaxComponent);