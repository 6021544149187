import React, {Component} from 'react';
import {createUrlParametersFromObject} from "../../../utils/httpUtils";
import {Route, Switch} from "react-router-dom";
import SelectConditionStep from "./SelectConditionStep";
import RecommendationComponent from "../RecommendationComponent";
import FilterStep from "./FilterStep";
import {truthyOrZero} from "../../../utils/intUtils";
import EditWaxBoxStep from "./EditWaxBoxStep";

class RecommendationFlow extends Component {

  state = {
    condition: null,
    filter: null
  }

  /**
   * NOTE: this will NOT run every time a route is hit. Just the first time.
   * */
  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const temperature = parseInt(searchParams.get('temperature'));
    const maybeTemperature = truthyOrZero(temperature) ? temperature : ''
    const maybeSnowTypeId = searchParams.get('snowTypeId');
    const condition = {temperature: maybeTemperature, snowTypeId: maybeSnowTypeId}
    this.setState({condition});
  }

  setCondition(condition) {
    this.setState({condition});
  }

  setFilter(filter) {
    this.setState({filter});
    this.props.history.push({
      pathname: '/recommendation/filter',
      search: this.urlParamsFromConditionAndFilter(this.state.condition, filter)
    });
  }

  goToFilterStep(condition) {
    const newSnowTypeId = condition.snowType ? condition.snowType.id : null
    const newCondition = {temperature: condition.temperature, snowTypeId: newSnowTypeId}
    this.setCondition(newCondition)
    this.props.history.push({
      pathname: '/recommendation/filter',
      search: this.urlParamsFromConditionAndFilter(newCondition, null)
    });
  }

  goToFilterAfterEditWaxBox() {
    this.props.history.push({
      pathname: '/recommendation/filter',
      search: this.urlParamsFromConditionAndFilter(this.state.condition, this.state.filter)
    });
  }

  setFilterAndGoToRecommendation(filter) {
    this.props.history.push({
      pathname: '/recommendation/result',
      search: this.urlParamsFromConditionAndFilter(this.state.condition, this.state.filter)
    });
  }

  goToEditWaxBox() {
    this.props.history.push({
      pathname: '/recommendation/edit-waxbox',
      search: this.urlParamsFromConditionAndFilter(this.state.condition, this.state.filter)
    })
  }

  render() {
    return (
       <div>
         <Switch>
           <Route
              exact
              path={`${this.props.match.path}/filter`}
              render={(routeProps) => {
                return <FilterStep
                   {...routeProps}
                   onProceed={(filter) => this.setFilterAndGoToRecommendation()}
                   onFilterChanged={(filter) => this.setFilter(filter)}
                   onAddMoreWaxes={() => this.goToEditWaxBox()}
                />
              }}
           />
           <Route
              exact
              path={`${this.props.match.path}/result`}
              render={(routeProps) => <RecommendationComponent {...routeProps}/>}
           />
           <Route
              exact
              path={`${this.props.match.path}/edit-waxbox`}
              render={(routeProps) => <EditWaxBoxStep
                 {...routeProps}
                 onDone={() => this.goToFilterAfterEditWaxBox()}/>}
           />
           <Route
              exact
              path={this.props.match.path}
              render={(routeProps) => <SelectConditionStep
                 {...routeProps}
                 onProceed={(condition) => this.goToFilterStep(condition)}/>
              }
           />
         </Switch>
       </div>

    );
  }

  urlParamsFromConditionAndFilter(condition, filter) {
    return createUrlParametersFromObject({
      temperature: condition.temperature,
      snowTypeId: condition.snowTypeId,
      brand: filter && filter.brand,
      waxType: filter && filter.waxType,
      myWaxes: filter && (filter.myWaxes || null)
    })
  }
}

export default RecommendationFlow;
