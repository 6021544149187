import React, {Component} from 'react';
import InformationField from "../../components/helperComponents/text/PageInformation";
import {connect} from "react-redux";
import {Button, FormField, Select} from "grommet";
import Header3 from "../../components/helperComponents/text/Header3";
import Page from "../../pages/Page";
import Header4 from "../../components/helperComponents/text/Header4";
import PrimaryButton from "../../components/helperComponents/buttons/PrimaryButton";
import {dispatchAction} from "../../../reduxStore/reduxUtils";
import OutlinedButton from "../../components/helperComponents/buttons/OutlinedButton";

class AdminUpdateData extends Component {

  state = {
    currentWaxIndex: -1,
    currentWax: null,
    newConditions: []
  }

  componentDidMount() {
    if (!this.props.admin.waxBrands.length) {
      this.props.dispatch(this.props.actions.fetchBrands());
    }
    if (!this.props.product.waxSubTypes.length) {
      this.props.dispatch(this.props.actions.fetchWaxSubTypes());
    }
    if (!this.props.condition.snowTypes.length) {
      this.props.dispatch(this.props.actions.fetchSnowTypes());
    }
    if (!this.props.product.waxProducts.length) {
      this.props.dispatch(this.props.actions.fetchWaxProducts());
    }
  }

  availableSnowTypes = ["nysnö", "finkornig snö", "grov snö", "isig snö", "alla snötyper"];
  snowTypesThatWaxShouldNotHaveToBeUpdated = ["nysnö", "finkornig snö", "grov snö", "isig snö"];

  render() {
    const allSnowTypes = this.props.condition.snowTypes;
    const allowedSnowTypes = allSnowTypes.filter(s => this.availableSnowTypes.includes(s.snowType));
    const waxesToUpdate = this.props.product.waxProducts
       .filter(w => !w.conditions.some(c => this.snowTypesThatWaxShouldNotHaveToBeUpdated.includes(c.snowType)))
       .filter(w => w.conditions.length === 1 && w.conditions[0].snowType !== 'alla snötyper')
    const currentWax = this.state.currentWax
    console.log("waxesToUpdate", waxesToUpdate);
    console.log("this.state.currentWax", this.state.currentWax);

    const searchLink = currentWax && encodeURI(`https://google.com/search?q=${currentWax.brand} ${currentWax.waxName} ${currentWax.waxSubType}`)

    const content = this.state.currentWaxIndex === -1 ? (<div>
      <PrimaryButton label={"Start"} onClick={() => this.goToNextWax()}/>
    </div>) : (
       <div>
         <InformationField>
           <p>This is currently only supporting to update wax where snow types should be updated</p>
         </InformationField>
         <div>
           <Header3
              text={`${currentWax.brand} - ${currentWax.waxName} - ${currentWax.id} | ${this.state.currentWaxIndex} av ${waxesToUpdate.length}`}/>
           <Header4 text={`${currentWax.waxType} - ${currentWax.waxSubType}`}/>
           {currentWax.conditions.map((condition, index) => (
              <div key={index} className="admin-update-wax-condition-container">
                <p>{condition.fromTemperature} / {condition.toTemperature}</p>
                <div className="admin-update-wax-condition">
                  <FormField label="Snötyp">
                    <Select
                       options={allowedSnowTypes.map(s => s.snowType)}
                       value={condition.snowType}
                       onChange={({option}) => this.updateConditionSnowType(option, index)}
                    />
                  </FormField>
                </div>
                <div onClick={() => this.deleteCondition(index)}>X</div>
              </div>
           ))}
           <FormField>
             <Button label="Ett till" onClick={() => this.addNewCondition()}/>
           </FormField>
         </div>
         <div className="admin-update-links">
           <div><a href={searchLink} target="_blank" rel="noopener noreferrer">Sök google</a></div>
           <div><a href={searchLink + '%20Polder'} target="_blank" rel="noopener noreferrer">Sök pölder</a></div>
         </div>
         <div>
           <div className={"admin-next-no-save-button"}>
             <OutlinedButton label="Next without save" onClick={() => this.goToNextWax()} type="primary-thin"/>
           </div>

           <PrimaryButton label={"Save and next"} onClick={() => this.saveAndNext()}/>
         </div>
       </div>
    );
    return <Page hideTopMenu={true}>{content}</Page>
  }

  addNewCondition() {
    const copy = {...this.state.currentWax}
    const template = copy.conditions[0]
    copy.conditions.push({
      fromTemperature: template.fromTemperature,
      toTemperature: template.toTemperature,
      airHumidity: template.airHumidity,
      snowType: ''
    });
    this.setState({currentWax: copy});
  }

  goToNextWax() {
    const nextIndex = this.state.currentWaxIndex + 1
    const waxesToUpdate = this.props.product.waxProducts
       .filter(w => !w.conditions.some(c => this.snowTypesThatWaxShouldNotHaveToBeUpdated.includes(c.snowType)))
       .filter(w => w.conditions.length === 1 && w.conditions[0].snowType !== 'alla snötyper')

    const nextWax = waxesToUpdate[nextIndex];
    this.setState({currentWaxIndex: nextIndex, currentWax: nextWax})
  }

  updateConditionSnowType(snowType, index) {
    const oldConditionAtPosition = this.state.currentWax.conditions[index];
    const newConditionAtPosition = {...oldConditionAtPosition, snowType: snowType}
    const copy = {...this.state.currentWax}
    copy.conditions[index] = newConditionAtPosition
    this.setState({currentWax: copy});
  }

  deleteCondition(index) {
    const copy = {...this.state.currentWax}
    if (copy.conditions.length === 0) return;
    copy.conditions.splice(index, 1)
    this.setState({currentWax: copy});
  }

  saveAndNext() {
    const allSnowTypes = this.props.condition.snowTypes;
    const actualConditions = this.state.currentWax.conditions.map(c => ({
      ...c,
      snowTypeId: allSnowTypes.find(s => s.snowType === c.snowType).id
    }));

    const wax = {...this.state.currentWax, conditions: actualConditions}
    dispatchAction(this, this.props.actions.updateWax(wax))
    this.goToNextWax();
  }
}

const mapStateToProps = state => ({
  admin: state.admin,
  condition: state.condition,
  product: state.product
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    actions: {...stateProps.admin.actions, ...stateProps.condition.actions, ...stateProps.product.actions},
    ...dispatchProps,
    ...ownProps
  }
};
export default connect(mapStateToProps, null, mergeProps)(AdminUpdateData);