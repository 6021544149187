import React, {Component} from 'react';
import FullPageSelect from "../../components/helperComponents/select/FullPageSelect";
import {connect} from "react-redux";
import {getSystemWaxType, systemWaxTypeToHuman} from "../../../utils/waxUtils";
import CheckboxInput from "../../components/helperComponents/input/CheckboxInput";
import {dispatchAction} from "../../../reduxStore/reduxUtils";
import OutlinedButton from "../../components/helperComponents/buttons/OutlinedButton";

class RecommendationFilter extends Component {
  state = {
    brand: null,
    waxType: null,
    myWaxes: false,
  }

  componentDidMount() {
    if (this.props.authentication.isLoggedIn) {
      dispatchAction(this, this.props.actions.fetchUserWaxBox());
    }

    const {initialFilter} = this.props;
    if (this.props.initialFilter) {
      this.setState({
        brand: initialFilter.brand || null,
        waxType: initialFilter.waxType || null,
        myWaxes: initialFilter.myWaxes || false
      })
    }
  }

  selectBrand(brandObj) {
    if (brandObj.name === "Alla märken") this.setState({brand: null}, () => this.provideFilter());
    else this.setState({brand: brandObj.name}, () => this.provideFilter());
  }

  provideFilter() {
    this.props.onFilterChange({brand: this.state.brand, waxType: this.state.waxType, myWaxes: this.state.myWaxes});
  }

  selectWaxType(waxTypeObj) {
    if (waxTypeObj.name === "Alla vallatyper") this.setState({waxType: null}, () => this.provideFilter())
    else this.setState({waxType: getSystemWaxType(waxTypeObj.name)}, () => this.provideFilter())
  }

  setMyWaxes(value) {
    this.setState({myWaxes: value}, () => this.provideFilter())
  }

  enterAddWaxesMode() {
    this.props.onAddMoreWaxes();
  }

  render() {
    const brandOptions = this.props.product.waxBrands.map(b => ({name: b}));
    brandOptions.push({name: 'Alla märken'});
    const waxTypeOptions = this.props.product.waxTypes.map(w => ({name: systemWaxTypeToHuman(w)}));
    waxTypeOptions.push({name: "Alla vallatyper"})
    const {brand, waxType} = this.props.initialFilter || {};
    const maybeInitialBrand = brandOptions.find(b => brand === b.name);
    const maybeInitialWaxType = waxTypeOptions.find(w => systemWaxTypeToHuman(waxType) === w.name);

    return (
       <div>
         <div className="recommendation-filter-brand-select-field">
           <FullPageSelect
              options={brandOptions}
              onOptionSelected={option => this.selectBrand(option)}
              initialValue={maybeInitialBrand}
              defaultText="Alla märken"
              selectTitle="Välj ett märke"
              label="Märke"
           />
         </div>
         <div className="recommendation-filter-waxtype-select-field">
           <FullPageSelect
              options={waxTypeOptions}
              onOptionSelected={option => this.selectWaxType(option)}
              initialValue={maybeInitialWaxType}
              defaultText="Alla typer"
              selectTitle="Välj en vallatyp"
              label="Vallatyp"
           />
         </div>
         <div>
           <CheckboxInput
              isChecked={this.state.myWaxes}
              label={"Visa enbart förslag från vallor jag äger."}
              onChange={value => this.setMyWaxes(value)}/>
         </div>
         {this.state.myWaxes && <div>
           <div className="my-waxes-user-information-text">
             <p>Du har lagt till {this.props.product.userWaxBoxItems.length} produkter.</p>
           </div>
           <div className="my-waxes-add-waxes-button">
             <OutlinedButton label="Lägg till fler" onClick={() => this.enterAddWaxesMode()} type="primary-thin"/>
           </div>
         </div>
         }
       </div>
    );
  }
}

const mapStateToProps = state => ({
  product: state.product,
  authentication: state.authentication
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    actions: {...stateProps.product.actions},
    ...dispatchProps,
    ...ownProps
  }
};


export default connect(mapStateToProps, null, mergeProps)(RecommendationFilter);