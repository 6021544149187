import React, {Component} from 'react';
import UserWaxBoxComponent from "../../components/product/user/UserWaxBoxComponent";
import PrimaryButton from "../../components/helperComponents/buttons/PrimaryButton";
import {connect} from "react-redux";
import LoginComponent from "../../components/user/login/LoginComponent";
import InformationField from "../../components/helperComponents/text/PageInformation";
import './_filterStyles.scss';
import {dispatchAction} from "../../../reduxStore/reduxUtils";

class EditWaxBoxComponent extends Component {

  fetchWaxBoxForUser() {
    dispatchAction(this, this.props.actions.fetchUserWaxBox())
  }

  render() {
    const isLoggedIn = this.props.authentication.isLoggedIn;
    return (
       <div className="edit-waxbox-container">
         {isLoggedIn ?
            <div>
              <InformationField><p>Klicka på knappen nedan och sök fram dina vallor.</p>
              </InformationField>
              <UserWaxBoxComponent disableProductClick={true}/>
              <PrimaryButton label="Klar" onClick={() => this.props.onDone()}/>
            </div> : null
         }
         {!isLoggedIn ? <div className="edit-wax-component-login-section">
           <InformationField><p>
             För att kunna lägga till vallor måste du ha ett konto hos Vallaloggen. Logga in eller skapa ett här nedan.
           </p></InformationField>
           <LoginComponent onSuccess={() => this.fetchWaxBoxForUser()}/>
         </div> : null}


       </div>
    );
  }
}

const mapStateToProps = state => ({
  product: state.product,
  authentication: state.authentication
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    actions: {...stateProps.product.actions},
    ...dispatchProps,
    ...ownProps
  }
};


export default connect(mapStateToProps, null, mergeProps)(EditWaxBoxComponent);