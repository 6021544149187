import events from '../../actionEvents.js'

export default function SettingsModule({settingsRepository}) {
  const baseState = {
    ...settingsRepository.getSettings(),
    actions: {
      setUseAdvancedSnowTypes
    }
  };

  function setUseAdvancedSnowTypes(use) {
    return async (dispatch) => {
      settingsRepository.updateSetting('useAdvancedSnowTypes', use);
      const newSettings = settingsRepository.getSettings();
      dispatch(events.setSettings(newSettings));
    }
  }

  return {
    reducer
  };

  function reducer(state = baseState, action) {
    switch (action.type) {
      case "SET_USER_SETTINGS": {
        return {
          ...state,
          ...action.settings
        }
      }
      default:
        return state;

    }
  }
}