import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './texts.scss'

const Header2 = (props) => {
  const maybeIcon = props.icon &&
     <span className="section-header-icon">
         <FontAwesomeIcon icon={props.icon} color={props.iconColor}/>
       </span>

  return (
     <span>
       <h2 className="vallaloggen-header-2">
         {maybeIcon && maybeIcon}
         {props.text}
       </h2>
     </span>
  );
};

export default Header2;