import React from 'react';
import './style/App.scss';
import AppRouter from "./app/routing/AppRouter";
import {Provider} from 'react-redux'
import {Grommet} from 'grommet';
import {theme} from './style/gromet/theme.js'
import configureStore from './reduxStore/storeSetup.js';
import CookieBar from "./app/components/client/CookieBar";
import analytics from "./analytics/analytics";
import EnableFontAwesomeIcons from "./EnableFontAwesomeIcons";

function App() {
  try {
    analytics.initialize()
  } catch (error) {
    console.error("Could not initialize analytics", error)
  } finally {
    return (
       <Provider store={configureStore()}>
         <Grommet theme={theme}>
           <EnableFontAwesomeIcons>
             <AppRouter/>
             <CookieBar/>
           </EnableFontAwesomeIcons>
         </Grommet>
       </Provider>
    );
  }


}

export default App;
