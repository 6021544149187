import React, {Component} from 'react';
import {Button, Form, FormField, TextInput, Select} from "grommet";
import ListItem from "./ListItem";
import {connect} from "react-redux";

class AddWaxSubTypeComponent extends Component {
  state = {
    subType: '',
    waxType: '',
    id: ''
  };

  componentDidMount() {
    if (!this.props.product.waxSubTypes.length) {
      this.props.dispatch(this.props.actions.fetchWaxSubTypes());
    }
  }

  setSubTypeName(subType) {
    this.setState({subType});
  }

  setSubTypeId(id) {
    this.setState({id});
  }

  setType(waxType) {
    this.setState({waxType})
  }

  submit() {
    this.props.dispatch(this.props.actions.createWaxSubtype(this.state));
    this.setState({subType: '', id: ''})
  }

  render() {
    const waxTypes = this.props.admin.waxTypes;
    const waxSubTypes = this.props.product.waxSubTypes;
    return (
       <div className="admin-add-container">
         <div>
           <h1>Lägg till snötyp</h1>
           <div className="admin-paper">
             <Form onSubmit={() => this.submit()} className="admin-form">
               <FormField label="Valla - Subtyp" name="subtype">
                 <TextInput
                    value={this.state.subType}
                    onChange={(e) => this.setSubTypeName(e.target.value)}
                 />
               </FormField>
               <FormField label="Vallatyp" name="type">
                 <Select
                    options={waxTypes}
                    value={this.state.waxType}
                    onChange={({option}) => this.setType(option)}
                 />
               </FormField>
               <FormField label="Eventuellt id (UUID)" name="subtypeid">
                 <TextInput
                    value={this.state.id}
                    onChange={(e) => this.setSubTypeId(e.target.value)}
                 />
               </FormField>
               <Button label="Skapa" onClick={() => this.submit()}/>
             </Form>
           </div>
           <div className="admin-paper">
             <pre>
               {JSON.stringify(this.props.admin.waxSubTypeResult, null, 4)}
             </pre>
           </div>
           <h3>Existerande valla subtyper</h3>
           <div>
             {waxSubTypes.length && waxSubTypes.map(waxSubType => <ListItem
                   key={waxSubType.id}
                   title={waxSubType.waxSubTypeName}
                   details={waxSubType}
                />
             )}
           </div>
         </div>
       </div>
    );
  }
}

const mapStateToProps = state => ({
  admin: state.admin,
  condition: state.condition,
  product: state.product
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    actions: {...stateProps.admin.actions, ...stateProps.product.actions},
    ...dispatchProps,
    ...ownProps
  }
};

export default connect(mapStateToProps, null, mergeProps)(AddWaxSubTypeComponent);