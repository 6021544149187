export const HUMAN_WAX_TYPES = ["Glid", "Fäste", "Riller"];

export function systemWaxTypeToHuman(systemWaxType) {
  return {
    glider: "Glid",
    kickwax: "Fäste",
    riller: "Riller"
  }[systemWaxType]
}

export function getSystemWaxType(humanWaxType) {
  return {
    Glid: "glider",
    Fäste: "kickwax",
    Riller: "riller"
  }[humanWaxType]
}