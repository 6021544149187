import React, {Component} from 'react';
import {connect} from "react-redux";
import PrimaryButton from "../../helperComponents/buttons/PrimaryButton";
import Input from "../../helperComponents/input/Input";
import colors from "../../../../style/colors";

class EmailLoginComponent extends Component {

  state = {
    email: '',
    exchangeCode: '',
    firstName: '',
    lastName: '',
    loadingSpinner: false,
    missingInfoError: ''
  };

  setEmail(email) {
    this.setState({email})
  }

  onSubmit() {
    this.props.dispatch(this.props.actions.loginByEmail(this.state.email));
    this.setState({loadingSpinner: true})
  }

  render() {
    const error = this.props.authentication.loginErrorMessage || this.state.missingInfoError;
    return (
       <div>
         <div>
           {this.props.authentication.loginStatus === '' && <div>
             <div className="login-instruction-text-container">
               <p>Ett E-post meddelande med en engångskod kommer att skickas till adressen du anger</p>
             </div>
             <div className="email-login-email-input">
               <Input
                  label="E-post"
                  icon="envelope"
                  iconcolor={colors.primary50}
                  onChange={(e) => this.setEmail(e.target.value)}
                  value={this.state.email}/>
               <div>
                 {error && <div className="email-login-error-message">
                   {error}
                 </div>}
               </div>
               <div className="email-login-button-container">
                 {this.state.loadingSpinner && !error
                    ? <div className="email-login-loading-spinner">
                      <div className="loader"/>
                    </div>
                    : <PrimaryButton
                       onClick={() => this.onSubmit()}
                       label="Logga in" type="submit"
                       className="email-login-login-button"
                    />
                 }
               </div>
             </div>
           </div>
           }
           {this.props.authentication.loginStatus === 'INIT_CREATE' && <div className="email-login-init-create-form">
             <div className="login-instruction-text-container">
               <strong>Du är snart där!</strong>
               <p>En engångskod har skickats till dig via E-post. Kopiera in eller skriv av den och ange dina uppgifter.
                 Sedan kan du börja skapa rapporter.</p>
             </div>
             <div className="email-login-exchange-code">
               <Input
                  label={"Engångskod"}
                  icon="key"
                  value={this.state.exchangeCode}
                  onChange={({target}) => this.setState({exchangeCode: target.value})}
               />
             </div>

             <div className="email-login-first-name">
               <Input
                  label="Förnamn"
                  value={this.state.firstName}
                  icon={["far", "user"]}
                  onChange={({target}) => this.setState({firstName: target.value})}
               />
             </div>
             <div className="email-login-last-name">
               <Input
                  label="Efternamn (frivilligt)"
                  icon={["far", "user"]}
                  value={this.state.lastName}
                  onChange={({target}) => this.setState({lastName: target.value})}
               />
             </div>
             <div>
               {error && <div className="email-login-error-message">
                 {error}
               </div>}
             </div>
             <div className="email-login-button-container">
               <PrimaryButton label="Registrera" onClick={() => this.createUser()}/>
             </div>
           </div>}
           {this.props.authentication.loginStatus === 'USER_EXIST' && <div className="email-login-returning-user-form">
             <div className="login-instruction-text-container">
               <strong>Välkommen tillbaka!</strong>
               <p>En engångskod har skickats till dig via E-post. Kopiera in eller skriv av den här nedanför för att
                 logga in.</p>
             </div>

             <div className="email-login-exchange-code">
               <Input
                  label="Engångskod"
                  icon="key"
                  onChange={({target}) => this.setState({exchangeCode: target.value})}
                  value={this.state.exchangeCode}
               />
             </div>
             <div>
               {error && <div className="email-login-error-message">
                 {error}
               </div>}
             </div>
             <div className="email-login-button-container">
               <PrimaryButton
                  label="Logga in"
                  className="email-login-login-user"
                  onClick={() => this.loginUserByCode()}
               />
             </div>
           </div>}
         </div>
       </div>
    );
  }

  createUser() {
    const {exchangeCode, lastName, firstName, email} = this.state;
    if (!this.validateCode() && !this.validateFirstName()) {
      this.props.dispatch(this.props.actions.createUserByCode(exchangeCode, {firstName, lastName, email}));
    }
  }

  loginUserByCode() {
    this.props.dispatch(this.props.actions.loginUserByCode(this.state.exchangeCode, this.state.email));
  }

  validateCode() {
    const missingInfoError = 'En engångskod måste anges'
    if (this.state.exchangeCode.length < 1) {
      this.setState({missingInfoError});
      return missingInfoError
    }
  }

  validateFirstName() {
    const missingInfoError = 'Förnamn måste anges'
    if (this.state.firstName.length < 1) {
      this.setState({missingInfoError})
      return missingInfoError;
    }
  }
}

const mapStateToProps = state => ({
  authentication: state.authentication
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    actions: {...stateProps.authentication.actions},
    ...dispatchProps,
    ...ownProps
  }
};


export default connect(mapStateToProps, null, mergeProps)(EmailLoginComponent);