import React, {Component} from 'react';
import {connect} from "react-redux";
import ReportPreview from "../reportPreview/ReportPreview";
import InformationField from "../../helperComponents/text/PageInformation";
import Header1 from "../../helperComponents/text/Header1";
import PrimaryButton from "../../helperComponents/buttons/PrimaryButton";

class UserReports extends Component {

  componentDidMount() {
    if (!this.props.reports.userReports.length) {
      this.props.dispatch(this.props.actions.fetchAllUsersReports());
    }
  }

  render() {
    const reports = this.props.reports.userReports;
    return (
       <div className="user-reports-container">
         <div className="user-reports-content">
           <div className="user-reports-description">
             <Header1 title="Mina rapporter"/>
             <InformationField><p>Här hittar du alla rapporter som du själv har skapat</p></InformationField>
             <PrimaryButton label="Skapa en ny rapport" onClick={() => this.goToCreateReport()}/>
           </div>
           {reports.length
              ? reports.map(report => {
                return <ReportPreview
                   key={report.id}
                   report={report}
                   onClick={() => this.goToDetailsPage(report.id)}
                />
              })
              : <div className="report-summary-no-results">
              </div>
           }
         </div>
       </div>
    );
  }

  goToCreateReport() {
    this.props.history.push(`/create/report`);
  }

  goToDetailsPage(reportId) {
    this.props.history.push(`/reports/${reportId}/details`);
  }
}

const mapStateToProps = state => ({
  reports: state.reports,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    actions: {...stateProps.reports.actions},
    ...dispatchProps,
    ...ownProps
  }
};
export default connect(mapStateToProps, null, mergeProps)(UserReports);