import events from '../../actionEvents.js'

export default function ClientInfoModule({clientInfoService, localStorageGateway}) {
  const baseState = {
    actions: {
      fetchServerConfig,
      hideCookieBar
    },
    googleClientId: "",
    hideCookieBar: !!localStorageGateway.get('hideCookieBar')
  };

  return {
    reducer: clientInfoReducer
  };

  function fetchServerConfig() {
    return async (dispatch) => {
      const clientInfo = await clientInfoService.fetchClientInfo();
      dispatch(events.clientInfoFetched(clientInfo))
    }
  }

  function hideCookieBar() {
    return async (dispatch) => {
      dispatch(events.hideCookieBar());
      localStorageGateway.set('hideCookieBar', true);
    }
  }

  function clientInfoReducer(state = baseState, action) {
    switch (action.type) {
      case "CLIENT_INFO_FETCHED":
        return {
          ...state,
          googleClientId: action.clientInfo.googleClientId
        };
      case "HIDE_COOKIE_BAR":
        return {
          ...state,
          hideCookieBar: true
        };
      default:
        return state;
    }
  }

}


// const baseState = {
//   googleClientId: ""
// };
//
// export const fetchServerConfig = () => {
//   return async (dispatch) => {
//     const clientInfo = await axios.get("/client-info");
//     dispatch(clientInfoFetched(clientInfo.data))
//   }
// };
//
// const clientInfoFetched = (clientInfo) => ({type: "CLIENT_INFO_FETCHED", clientInfo});
//
// export const clientInfoReducer = (state = baseState, action) => {
//   switch (action.type) {
//     case "CLIENT_INFO_FETCHED":
//       return {
//         ...state,
//         ...action.clientInfo
//
//       };
//     default:
//       return baseState
//   }
// };


