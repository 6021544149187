import React, {Component} from 'react';
import {Link} from "react-router-dom";

class AdminPage extends Component {
  render() {
    return (
       <div className="admin-page-container">
         <div className="admin-paper admin-page-content">
           <Link to='/admin/add'>
             <h3>Lägg till data</h3>
           </Link>
         </div>
         <div className="admin-paper admin-page-content">
           <Link to='/admin/update'>
             <h3>Uppdatera  data</h3>
           </Link>
         </div>
         <div className="admin-paper admin-page-content">
           <Link to='/admin/calculate-wax-scores'>
             <h3>Synka vallor</h3>
           </Link>
         </div>
       </div>
    );
  }
}

export default AdminPage;