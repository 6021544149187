import React, {Component} from 'react';
import Page from "../Page";

class PrivacyPolicy extends Component {
  render() {
    return (
       <Page>
         <div style={{
           display: "flex",
           justifyContent: "center",
           alignItems: "center",
           marginTop: "32px",
           marginBottom: "128px"
         }}>
           <div>
             <div style={{maxWidth: "75vw"}}>
               <h1>Integritetspolicy för vallaloggen</h1>
               Genom att använda dig av och/eller registrera dig på vallaloggen.se godkänner du sparande av personlig
               data enligt detta dokument
               <h2>Vilken information kommer Vallaloggen spara om mig?</h2>
               <ul>
                 <li>Förnamn och efternamn</li>
                 <li>Epost</li>
                 <li>Eventuell profilbild</li>
               </ul>
               <i style={{fontSize: '12px'}}>Förutsatt att du registrerar dig. Registrerar du dig inte, sparas ingen
                 information.</i>
               <h2>Hur används min information?</h2>
               Din personinformation används i första hand för att kunna spara information knutet till ditt
               användarkonto. Till exempel vallarapporter.
               I vissa förekommande fall kommer din epost också att användas för att skicka ut information.
               Om du inte registrerar dig sparas ingen personlig information.
               <h2>Hur länge lagras mina uppgifter?</h2>
               Din personliga information kommer att sparas fram till att du väljer att avsluta ditt konto hos
               vallaloggen.
               <h2>Med vem delas min information</h2>
               Vallaloggen säljer inte personlig information till tredjeparter.
               Ditt för- och efternamn kommer att synas i anslutning till dina rapporter.
               Vallaloggen använder Google analytics för att kunna förbättra användarflöden och funtionalitet.
               Vallaloggen använder Hotjar för att samla in feedback och analysera förbättringsmöjligheter.

               <h3>Hotjar</h3>
               <p><em>
                 Vallaloggen använder Hotjar för att bättre förstå våra användares behov, och för att optimera
                 upplevelsen. Hotjar är en tjänst som hjälper oss att förstå användarbeteende. Till exempel, hur
                 mycket tid spänderas på olika sidor, vilka länkar klickas på, vad gillas / ogilas på sidan. Detta
                 låter oss utveckla sidan utefter användarfeedback. Hotjar använder cookies (kakor) och andra tekniska
                 hjälpmedel för att samla information om användarbeteende och användarens enheter. Detta inkluderar
                 IP-address (bearbetad under din session och lagrad oidentifierbar),skärmstorlek, enhetstyp (unika
                 enhetsidentifierare), webbläsarinformation, geografisk plats (enbart land) och användarens föredragna
                 språk. Hotjar lagrar denna information i en pseudonymiserad användarprofil. Hotjar är förbjudna
                 enligt kontrakt att sälja något av datan de samlar in på våra vägnar.
               </em></p>
               <p><em>För mer information, vänligen läs avsnittet `about Hotjar` på <a
                  href="https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar">Hotjars support sida</a>
               </em></p>
               <h2>Vart finns min information?</h2>
               Vallaloggens servrar finns hos DigitalOcean i Nederländerna som är en server-leverantör där vallaloggen
               hostas. Serverleverantören har dock inte tillgång till din data.
               <h2>Cookies / Kakor</h2>
               Vallaloggen använder cookies för att din upplevelse ska bli bättre. Genom att använda vallaloggen ger du
               också din tillåtelse att spara cookies.
               <h2>Updatteringar</h2>
               Detta dokument kan komma att updatteras i framtiden. Senaste updattering var 2020-09-27
               <h2>Jag vill veta mer</h2>
               Om du vill veta mer, har frågor eller klagomål skicka ett mail till info@vallaloggen.se
             </div>
           </div>
         </div>
       </Page>
    );
  }
}

export default PrivacyPolicy;