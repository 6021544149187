import React, {Component} from 'react';
import {systemWaxTypeToHuman} from '../../../../utils/waxUtils';
import ReportPreview from '../../report/reportPreview/ReportPreview';
import colors from "../../../../style/colors";
import './_productDetails.scss';
import Header2 from "../../helperComponents/text/Header2";
import {groupBy} from "../../../../utils/groupBy";
import InformationField from "../../helperComponents/text/PageInformation";
import Header6 from "../../helperComponents/text/Header6";
import {SpecificationField} from "../../helperComponents/information/SpecificationField";

class ProductDetailsComponent extends Component {

  title(productDetails) {
    return `${productDetails.brand} ${productDetails.waxName}`
  }

  goToReportDetails(report) {
    this.props.history.push(`/reports/${report.id}/details`)
  }

  render() {
    const {productDetails} = this.props;

    const userReports = productDetails.reports.filter(r => r.createdBy === this.props.currentUserId)
    const otherReports = productDetails.reports.filter(r => r.createdBy !== this.props.currentUserId)
    const productHasReports = !!productDetails.reports.length;

    const waxSubType = this.props.waxSubTypes.find(ws => productDetails.waxSubTypeId === ws.id).waxSubTypeName;
    return (
       <div className="product-detail-container">
         <div>
           <div className="product-details-title">
             <Header2 text={this.title(productDetails)} icon="circle" iconColor={colors.primary20}/>
           </div>
           <div>
             <SpecificationField
                left={systemWaxTypeToHuman(productDetails.waxType)}
                right={waxSubType}
                className="product-details-type"/>
             <div>{this.renderCondition(productDetails.conditions)}</div>
           </div>
         </div>
         <div>
           {productHasReports
              ? <div>
                {!!userReports.length &&
                <div>
                  <Header6 text="Dina rapporter"/>
                  <div className="product-details-user-reports">
                    {userReports.map(r => <ReportPreview
                       key={r.id}
                       report={r}
                       onClick={() => this.goToReportDetails(r)}/>)}
                  </div>
                </div>}
                {!!otherReports.length && <div>
                  <Header6 text="Rapporter av andra"/>
                  <div className="product-details-other-reports">
                    {otherReports.map(r => <ReportPreview
                       key={r.id}
                       report={r}
                       onClick={() => this.goToReportDetails(r)}/>)}
                  </div>
                </div>}
              </div>
              : <div className="product-preview-no-reports">
                <InformationField>
                  <p>Det finns inga rapporter för denna vallan ännu.
                  </p>
                </InformationField>
              </div>
           }
         </div>
       </div>
    );
  }

  renderCondition(conditions) {
    const simpleConditions = this.simplifyConditions(conditions)
    return <div>
      {simpleConditions.map((condition, index) => {
        return (
           <div key={index} className="product-details-condition kristoffer">
             <SpecificationField
                left={condition.temperatureString}
                right={condition.snowTypeNames.join(', ')}
                leftIcon="thermometer-quarter"
                rightIcon="snowflake"
                iconColor={colors.primary50}
             />
           </div>
        );
      })}
    </div>
  }

  // Creates a simpler condition where all conditions with same temperature but different
  // snow type will be in the same group
  // [{from: 0, to: 10, snow: old},{from: 0, to: 10, snow: new}] => [{from: 0, to: 10, snow: [old, new]}]
  simplifyConditions(conditions) {
    const groupedByTemperature = groupBy(conditions.map(c => ({
      ...c,
      keyName: `${c.fromTemperature} / ${c.toTemperature}`
    })), 'keyName');

    return Object.entries(groupedByTemperature).map(([key, conditions]) => {
      const allSnowTypes = conditions.map(c => this.props.snowTypes.find(s => s.id === c.snowTypeId).snowType)
      return {temperatureString: key, snowTypeNames: allSnowTypes}
    });
  }
}

export default ProductDetailsComponent;

