import React from 'react';
import Page from "./Page";
import RecommendationFlow from "../search/flow/RecommendationFlow";
import InformationField from "../components/helperComponents/text/PageInformation";

const RecommendationPage = (props) => {
  const isFirstStepOfFlow = `${props.location.pathname}`.endsWith('/recommendation');
  return (
     <Page disableNavigationField={isFirstStepOfFlow}>
       <div className="recommendation-page-container">
         {isFirstStepOfFlow &&
         <InformationField>
           <p>Här kan du få rekommendationer samt hitta inspiration från hur andra vallat i liknande fören.</p>
         </InformationField>}
         <RecommendationFlow {...props}/>
       </div>
     </Page>
  );
};

export default RecommendationPage;