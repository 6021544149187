import React from 'react';
import './texts.scss'

const Header1 = (props) => {
  return (
     <span className="vallaloggen-header-1">
        <h1 style={{color: props.color}}>{props.title}</h1>
     </span>
  );
};

export default Header1;