import React from 'react';

const ListItemCard = (props) => {
  function onItemClicked() {
    props.onClick && props.onClick()
  }

  const isClickable = !!props.onClick;
  const classes = isClickable ? 'vallaloggen-list-item-card vallaloggen-list-item-card-clickable' : 'vallaloggen-list-item-card'
  return (
     <div className={classes} onClick={() => onItemClicked()}>
       {props.children}
     </div>
  );
};

export default ListItemCard;