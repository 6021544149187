import React from 'react';
import './buttons.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Button = (props) => {

  const createRipple = (event) => {
    const button = event.currentTarget;

    const circle = document.createElement("span");
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
    circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
    circle.classList.add("vallaloggen-ripple");

    const ripple = button.getElementsByClassName("vallaloggen-ripple")[0];

    if (ripple) {
      ripple.remove();
    }

    button.appendChild(circle);

    circle.addEventListener('animationend', () => {
      button.removeChild(circle)
    })
  }


  return (
     <div className="vallaloggen-button-container">
       <button
          className={props.className}
          onMouseDown={e => createRipple(e)}
          onTouchStart={e => createRipple(e)}
          onClick={() => props.onClick()}>
         {props.icon || props.image ? < div className="vallaloggen-icon-button-body">
              <span className="vallaloggen-button-icon">
                {props.icon ? <FontAwesomeIcon icon={props.icon} color={props.iconColor}/> : props.image}
              </span>
              <span>{props.label}</span>
            </div>
            : props.label
         }
       </button>
     </div>
  );
};

export default Button;
