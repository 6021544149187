import React, {Component} from 'react';
import ReportDetailsProviderComponent from "../components/report/details/ReportDetailsProviderComponent";
import Page from "./Page";

class ReportDetailsPage extends Component {
  render() {
    const comingFrom = this.props.location.state && this.props.location.state.from;
    const backTextBasedOnLastLocation = {
      SEARCH: "Sök",
      USER_PAGE: "Tillbaka"
    }[comingFrom] || 'Tillbaka'
    return (
       <Page navigationProps={{label: backTextBasedOnLastLocation}}>
         <ReportDetailsProviderComponent {...this.props}/>
       </Page>
    );
  }
}

export default ReportDetailsPage;


