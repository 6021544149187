import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './texts.scss';
import colors from "../../../../style/colors";

const InformationField = (props) => {
  return (
     <div className="vallaloggen-information-field">
       <div className="vallaloggen-information-field-content">
         <div className="vallaloggen-information-field-icon-container">
           <FontAwesomeIcon icon={["fas", "info-circle"]} color={colors.primary50}/>
         </div>
         <div className="vallaloggen-information-field-child">
           {props.children}
         </div>
       </div>
     </div>
  );
};

export default InformationField;