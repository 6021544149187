import React, {Component, createRef} from 'react';
import {TextInput} from "grommet";
import {connect} from "react-redux";
import {Next, Previous, Search, SearchAdvanced} from "grommet-icons";
import {scrollTop} from "../../../utils/scrollUtils";
import PrimaryButton from "../helperComponents/buttons/PrimaryButton";
import Input from "../helperComponents/input/Input";

class ProductSearchSelect extends Component {
  constructor(props) {
    super(props);
    this.focusRef = createRef();
    this.focusInputRef = createRef();
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  state = {
    active: false,
    query: '',
    cursor: -1
  };

  search() {
    this.props.dispatch(this.props.actions.searchProductsByText(this.state.query))
  };

  getDisplayNameForWax(wax) {
    return `${wax.brand} ${wax.waxName} (${wax.waxSubType})`
  }

  selectWax(wax) {
    this.props.onSelect(wax);
    this.inactivate();
    this.props.dispatch(this.props.actions.setSearchResults([]));
  }

  async activate() {
    this.scrollToSearchField();
    await this.setState({active: true}, () => this.focusInputRef.current.focus());
    document.querySelector('.product-search-select-search-container').addEventListener("keydown", this.handleKeyDown);
  }

  inactivate() {
    document.querySelector('.product-search-select-search-container').removeEventListener("keydown", this.handleKeyDown);
    this.scrollToSearchField();
    this.setState({active: false, query: '', cursor: -1});
  }

  scrollToSearchField() {
    scrollTop();
    setTimeout(() => scrollTop(), 500)
  }

  handleKeyDown(event) {
    if (event.keyCode === 27) {
      this.inactivate()
    }
    const {cursor} = this.state;
    // arrow up/down button should select next/previous list element
    if (event.keyCode === 38 && cursor > 0) {
      this.setState(prevState => ({
        cursor: prevState.cursor - 1
      }), () => this.focusRef.current.focus());
      event.preventDefault();
    } else if (event.keyCode === 38 && cursor === 0) {
      this.focusInputRef.current.focus();
      this.setState({cursor: -1});
      event.preventDefault();
    } else if (event.keyCode === 40 && cursor < this.props.product.searchResult.length - this.props.ignoreIds.length - 1) {
      this.setState(prevState => ({
        cursor: prevState.cursor + 1
      }), () => this.focusRef.current.focus());
      event.preventDefault();
    }
  }

  renderPlaceholder() {

    if (this.props.triggerComponentType === 'ROUNDED_BUTTON') {
      return <div>
        <div
           onClick={() => this.activate()}
           className="product-search-select-floating-rounded-button">
          <SearchAdvanced color={"white"}/>
        </div>
      </div>
    } else if (this.props.triggerComponentType === 'PRIMARY_BUTTON') {
      return <div>
        <PrimaryButton label="Lägg till" onClick={() => this.activate()}/>
      </div>
    } else {
      return <div
         tabIndex={0}
         onKeyPress={(event) => {
           event.key === 'Enter' && this.activate()
         }}
         className="product-search-select-input-placeholder-container"
         onClick={() => this.activate()}>
        <Input disabled label="Lägg till vallorna du använde" placeholder="Klicka för att lägga till"
               actiontext="Lägg till"/>
      </div>;
    }
  }

  render() {
    const products = this.props.ignoreIds && this.props.ignoreIds.length
       ? this.props.product.searchResult.filter(p => !this.props.ignoreIds.includes(p.id))
       : this.props.product.searchResult;
    return (
       <div>
         {!this.state.active && this.renderPlaceholder()}
         {this.state.active && (
            <div
               className="product-search-select-search-container"
            >
              <div className="product-search-select-input-search-container">
                <div className="product-search-select-back" onClick={() => this.inactivate()}>
                  <Previous/>
                </div>
                <div className="product-search-select-search-input-container">
                  <TextInput
                     ref={this.focusInputRef}
                     type="text"
                     plain={true}
                     placeholder={this.state.query.length ? "" :
                        <span className="product-search-select-input-placeholder-text"><Search/>&nbsp;  Sök</span>}
                     className="product-search-select-search-input"
                     onChange={async (e) => {
                       await this.setState({query: e.target.value});
                       if (this.props.disableThrottle) {
                         this.search();
                       } else {
                         this.throttle(() => this.search());
                       }
                     }}
                  />
                </div>
              </div>
              <div className="product-search-select-results">
                {products.map((wax, index) => (
                   <div
                      tabIndex={0}
                      ref={this.state.cursor === index && this.focusRef}
                      onKeyPress={(event) => {
                        event.key === 'Enter' && this.selectWax(wax)
                      }}
                      onClick={() => this.selectWax(wax)} key={wax.id}
                      className="product-search-select-search-result">
                     <div>{this.getDisplayNameForWax(wax)}</div>
                     <div><Next/></div>
                   </div>
                ))}
              </div>
            </div>
         )}
       </div>
    );
  }

  timeout = null;


  throttle(method, scope) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(function () {
      method.call(scope);
    }, 300);
  }
}

const mapStateToProps = state => ({
  product: state.product
});
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    actions: {...stateProps.product.actions},
    ...dispatchProps,
    ...ownProps
  }
};


export default connect(mapStateToProps, null, mergeProps)(ProductSearchSelect);