import React, {Component} from 'react';
import {Button, Form, FormField, Keyboard, TextInput} from "grommet";
import {connect} from "react-redux";
import ListItem from "./ListItem";

class AddSnowTypeComponent extends Component {
  state = {
    snowTypeName: '',
    id: ''
  };

  componentDidMount() {
    if (!this.props.condition.snowTypes.length) {
      this.props.dispatch(this.props.actions.fetchSnowTypes());
    }
  }

  setSnowTypeName(snowTypeName) {
    this.setState({snowTypeName});
  }

  setSnowTypeId(id) {
    this.setState({id});
  }

  submit() {
    this.props.dispatch(this.props.actions.createSnowType(this.state));
    this.setState({snowTypeName: '', id: ''})
  }

  render() {
    const snowTypes = this.props.condition.snowTypes;
    return (
       <div className="admin-add-container">
         <div>
           <h1>Lägg till snötyp</h1>
           <div className="admin-paper">
             <Keyboard onEnter={() => this.submit()}>
               <Form onSubmit={() => this.submit()} className="admin-form">
                 <FormField label="Snötype" name="snowtype">
                   <TextInput
                      value={this.state.snowTypeName}
                      onChange={(e) => this.setSnowTypeName(e.target.value)}
                   />
                 </FormField>
                 <FormField label="Eventuellt id (UUID)" name="snowid">
                   <TextInput
                      value={this.state.id}
                      onChange={(e) => this.setSnowTypeId(e.target.value)}
                   />
                 </FormField>
                 <Button label="Skapa" onClick={() => this.submit()}/>
               </Form>
             </Keyboard>
           </div>
           <div className="admin-paper">
             <pre>
               {JSON.stringify(this.props.admin.snowTypeResult, null, 4)}
             </pre>
           </div>
           <h3>Existerande snötyper ({snowTypes.length})</h3>
           <div>
             {snowTypes.length && snowTypes.map(snowType => <ListItem
                key={snowType.id} title={`${snowType.snowType} (${this.getWaxFiltered(snowType.snowType).length})`}
                details={snowType}/>)}
           </div>
         </div>
       </div>
    );
  }

  getWaxFiltered(snowTypeName) {
    return snowTypeName
       ? this.props.product.waxProducts.filter(w => w.conditions.some(c => c.snowType === snowTypeName))
       : this.props.product.waxProducts;
  }
}

const mapStateToProps = state => ({
  admin: state.admin,
  condition: state.condition,
  product: state.product
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    actions: {...stateProps.admin.actions, ...stateProps.condition.actions},
    ...dispatchProps,
    ...ownProps
  }
};


export default connect(mapStateToProps, null, mergeProps)(AddSnowTypeComponent);