export default {
  clientInfoFetched: (clientInfo) => ({type: "CLIENT_INFO_FETCHED", clientInfo}),
  setLoggedIn: (loggedIn) => ({type: "SET_LOGGED_IN", loggedIn}),
  hideCookieBar: () => ({type: "HIDE_COOKIE_BAR"}),
  setUser: (user) => ({type: 'SET_USER', user}),
  setSnowTypes: (snowTypes) => ({type: 'SET_SNOW_TYPE', snowTypes}),
  setWaxProducts: (waxProducts) => ({type: 'SET_WAX_PRODUCTS', waxProducts}),
  setIsFetchingWaxProducts: (isFetchingWaxProducts) => ({
    type: 'SET_IS_FETCHING_WAX_PRODUCTS', isFetchingWaxProducts
  }),
  setIsFetchingRecommendationResults: (isFetching) => ({type: 'SET_IS_FETCHING_RECOMMENDATION_RESULTS', isFetching}),
  setWaxSearchResult: (waxProducts) => ({type: 'SET_WAX_SEARCH_RESULT', waxProducts}),
  setWaxRecommendationsResult: (recommendations) => ({type: 'SET_WAX_RECOMMENDATIONS_RESULT', recommendations}),
  setSearchResults: (searchResults) => ({type: 'SET_SEARCH_RESULTS', searchResults}),
  setCurrentReport: (report) => ({type: 'SET_CURRENT_REPORT', report}),
  setUserReports: (reports) => ({type: 'SET_USER_REPORTS', reports}),
  reportNotFound: (reportId) => ({type: 'REPORT_NOT_FOUND', reportId}),
  setReportCreated: (report) => ({type: 'SET_REPORT_CREATED', report}),
  setVoteFailed: () => ({type: 'VOTE_FAILED'}),
  resetVoteFailed: () => ({type: 'RESET_VOTE_FAILED'}),
  setUserSpecificsForReport: (userSpecifics) => ({type: 'SET_USER_SPECIFICS', userSpecifics}),
  setUserVoteType: (voteType) => ({type: 'SET_USER_VOTE_TYPE', voteType}),
  setfetchUserStatus: (fetchUserStatus) => ({type: 'SET_IS_FETCHING_USER', fetchUserStatus}),
  setLoginError: (errorMessage) => ({type: 'SET_LOGIN_ERROR', errorMessage}),
  setLoginStatus: (status) => ({type: 'SET_LOGIN_STATUS', status}),
  setClientTokenForUserLogin: (clientToken) => ({type: 'SET_CLIENT_TOKEN', clientToken}),
  setUserDeleted: (userDeleted) => ({type: 'SET_USER_DELETED', userDeleted}),
  setIsFirstTimeUser: (is) => ({type: 'SET_IS_FIRST_TIME_USER', is}),
  setSettings: (settings) => ({type: 'SET_USER_SETTINGS', settings}),
  setWaxSubTypes: (waxSubTypes) => ({type: 'SET_WAX_SUB_TYPES', waxSubTypes}),
  setUserWaxBox: (userWaxBox) => ({type: 'SET_USER_WAX_BOX', userWaxBox}),
  setCurrentProductDetails: (productDetails) => ({type: 'SET_PRODUCT_DETAILS', productDetails}),
  setCurrentReportDetails: (details) => ({type: 'SET_CURRENT_REPORT_DETAILS', details}),
}