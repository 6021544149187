import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import './_information.scss';

export const SpecificationField = (props) => {

  return (
     <div className={"specification-field " + props.className}>
       <div className="specification-field-content">
         <div className="specification-field-info specification-field-info-left">
           {!!props.leftIcon &&
           <FontAwesomeIcon icon={props.leftIcon} color={props.leftIconColor || props.iconColor}
                            className="specification-field-icon"/>}
           <span className="specification-text-left">{props.left}</span>
         </div>
         <div className="specification-field-info specification-field-info-right">
           {!!props.rightIcon &&
           <FontAwesomeIcon icon={props.rightIcon} color={props.rightIconColor || props.iconColor}
                            className="specification-field-icon"/>}
           <span className="specification-text-right">{props.right}</span>
         </div>
       </div>

     </div>
  )
}