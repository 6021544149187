import React from 'react';
import {Link} from "react-router-dom";
import './buttons.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const OutlinedLinkButton = (props) => {
  return (
     <div className="vallaloggen-button-container">
       <Link to={props.to}>
         <div className="vallaloggen-button-outline-link">
           {!!props.icon && <FontAwesomeIcon icon={props.icon}/>}<span>{props.text}</span>
         </div>
       </Link>
     </div>
  );
};

export default OutlinedLinkButton;