import React from 'react';
import './_loading.scss'

const SkeletonLoader = (props) => {
  return (
     <div className="vallaloggen-skeleton-loader">
       {props.instructions.map((instruction, i) => {
         return (<div key={i}>
           <Skeleton type={instruction}/>
         </div>)
       })}
     </div>
  );
};


const Skeleton = (props) => {
  const classMap = {
    line: 'skeleton-loader-line',
    header: 'skeleton-loader-header',
    block: 'skeleton-loader-block',
    pause32: 'skeleton-loader-pause32'
  }
  return (
     <div className={`skeleton-loader-skeleton ${classMap[props.type]}`}>
     </div>
  )
}

export default SkeletonLoader;
