import React from 'react';
import './style.scss'

const RadioButtonInput = (props) => {

  const {selectedOption, options} = props

  return (
     <div className="vallaloggen-radio-button-input">
       <div className="vallaloggen-label-input vallaloggen-radio-input-title"><label>{props.title}</label></div>
       <div className="vallaloggen-radio-button-options">
         {options.map(option => (
            <label key={option.value} className="vallaloggen-radio-button-label">
              <input
                 value={option.value}
                 type="radio"
                 checked={option.value === selectedOption}
                 onChange={(e) => props.onSelect(e.target.value)}/>
              <span>{option.text}</span>
            </label>
         ))}
       </div>
     </div>
  );
};

export default RadioButtonInput;