import React from 'react';
import "./texts.scss"

const Header6 = (props) => {
  return (
     <span>
       <h6 className="vallaloggen-header-6">{props.text}</h6>
     </span>
  );
};

export default Header6;