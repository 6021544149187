import React from 'react';
import './product-preview.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import colors from "../../../../style/colors";
import ListItemCard from "../../helperComponents/lists/ListItemCard";
import {Emoji, emojiTypes} from "../../helperComponents/text/Emoji";

const ProductPreview = (props) => {
  
  const getEmojiForScore = () => {
    if (meanScore >= 2.5) return emojiTypes.VERY_HAPPY
    else if (meanScore >= 1.9 && meanScore <= 2.5) return emojiTypes.HAPPY
    else return emojiTypes.SAD
  }

  const product = props.product;
  const meanScore = props.maybeMeanScore
  const numberOfReports = props.maybeNumberOfReports;

  const onProductClicked = () => {
    props.onProductClicked && props.onProductClicked(product)
  }

  return (
     <ListItemCard onClick={() => onProductClicked()}>
       <div className="product-preview-content">
         <div className="product-preview-left">
           <div className="product-preview-icon-container">
             <FontAwesomeIcon icon="circle" color={colors.success05} size="xs"/>
           </div>
           <div className="product-preview-title">{product.brand} {product.waxName}</div>
         </div>
         <div className="product-preview-right">
           {!!meanScore && <div>
             <span className="product-preview-score">{meanScore.toFixed(1)}</span>
             <span className="product-preview-emoji-container">
               <Emoji symbol={getEmojiForScore()}/>
             </span>
           </div>}
           {!!numberOfReports &&
           <div className="product-preview-number-of-reports-container">
             <span className="product-preview-number-of-reports">{numberOfReports}</span>
             <span><FontAwesomeIcon icon={["far", "chart-bar"]} color={colors.primary50}/></span>
           </div>
           }
         </div>
       </div>
     </ListItemCard>
  );
};

export default ProductPreview;