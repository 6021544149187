import React from 'react';
import {
  dateToDateString,
  dateToJsonExcludingTime,
  dateToTimeString,
  timeStringToJson
} from "../../../../../utils/dateTime";
import set from 'date-fns/set'

class DateTimeInput extends React.Component {

  state = {
    selectedDateTime: this.props.dateTime
  }

  updateDate(newDate) {
    const oldDateTime = new Date(this.state.selectedDateTime.getTime())
    const updatedDateTime = set(oldDateTime, dateToJsonExcludingTime(new Date(newDate)))
    this.setState({selectedDateTime: updatedDateTime});
    this.props.onChange(updatedDateTime)
  }

  updateTime(newTime) {
    const oldDateTime = new Date(this.state.selectedDateTime.getTime())
    const updatedDateTime = set(oldDateTime, timeStringToJson(newTime))
    this.setState({selectedDateTime: updatedDateTime});
    this.props.onChange(updatedDateTime)
  }

  render() {
    return (
       <div>
         <label className="vallaloggen-label-input">{this.props.label}</label>
         <div className="vallaloggen-datetimepicker">
           <input
              value={dateToDateString(this.props.dateTime)}
              onChange={(e) => this.updateDate(e.target.value)}
              type="date"
              id="date"
              className="vallaloggen-date-input"
              max={this.props.max && dateToDateString(this.props.max)}
           />
           <span></span>
           <input
              value={dateToTimeString(this.props.dateTime)}
              onChange={e => this.updateTime(e.target.value)}
              type="time"
              id="time"
              className="vallaloggen-time-input"
           />
         </div>
       </div>
    );
  }
}

export default DateTimeInput;

// https://codepen.io/herteleo/pen/LraqoZ