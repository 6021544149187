import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Page from "../Page";

class TermsAndConditions extends Component {
  render() {
    return (
       <Page>
         <div style={{
           display: "flex",
           justifyContent: "center",
           alignItems: "center",
           marginTop: "32px",
           marginBottom: "128px"
         }}>
           <div>
             <div style={{maxWidth: "75vw"}}>
               <h1>Användarvilkor</h1>
               När det refereras till "Vallaloggen", "oss", "vår" och "vi" menas Vallaloggen och dess upphovsmakare.
               Vid referens till service, produkt eller tjänsten menas Vallaloggens webplatform, dess
               affärsdata, dess API'er och funktioner.

               Vid referenser till "Du" eller "Användaren" avses alla individer som registererar sig och / eller
               använder tjänsten.
               <h2>Godkännande av vilkor</h2>
               Detta är ett avtal mellan dig och Vallaloggen. Genom att använda tjänsten godkänner du alla delar i dessa
               användarvilkor och <Link to="/privacy-policy">Integritetspolicyn</Link>. Använd inte denna tjänst om du
               inte accepterar dessa.

               <h2>Registrering</h2>
               För att använda vissa funktioner i denna tjänst måste du registrera ett användarkonto. För att skapa ett
               konto måste du tillhandahålla viss nödvänding information. Du är själv ansvarig för ditt konto och all
               relaterad aktivitet. Dela det därför inte med andra.

               <h2>Användarens ansvar och ägandeskap</h2>
               Du accepterar att du själv har fullt ansvar för den informationen du lägger upp på tjänsten, och har
               nödvändiga tillstånd att göra detta. Du äger informationen du lägger upp, men godkänner att vallaloggen
               använder denna information för att utveckla och förbättra produkten. Du godkänner även att din data
               kan ses av andra användare och besökare hos tjänsten. Du godkänner att om du väljer att avsluta ditt
               konto så kommer Vallaloggen att behålla de rapporter du inte manuellt har tagit bort.

               <h2>Tjänstens tillgänglighet</h2>
               Vallaloggen är i uppstartsfasen och kan komma att vara otillgänglig vid vissa tillfällen. Dock strävar vi
               efter att ha så bra upptid som möjligt.

               <h2>Datasäkerhet</h2>
               Vallaloggen strävar alltid efter säkerhet och att använda best practices inom säkerhet.
               <ul>
                 <li>All data skickas över HTTPS (TLS/SSL) vilket innebär krypterad nätvärkstrafik mellan system</li>
                 <li>Lösenord används inte, och engångskoder tas bort när de används, och oanvända koder blir ogiltiga
                   efter en timme
                 </li>
               </ul>
               <h2>Immateriella rättigheter</h2>
               Du godkänner och respekterar den skyddade källkoden för denna tjänst. Du godkänner att det inte är
               tillåtet att använda reverse enginering på tjänsten. Du kommer också att respektera vallaloggens
               varumärke.

               <h2>Övrig använding</h2>
               Du godkänner att du inte kommer att hacka tjänsten, avsiktligt överbelasta eller på annat sätt skada
               produkten. Om vi misstänker att du gjort dig skyldig till detta godkänner du att vi utan förvarning kan
               ta bort ditt konto.

               <h2>Uppdatteringar och förändingar av Användarvilkoren</h2>
               Vid mindre förändringar kommer dessa att synas på denna sida. Vid större förändringar kommer du promptas
               att godkänna dessa på nytt. Senaste updatteringen för dessa vilkor var 2019-11-09

               <h2>Kontaktinformation</h2>
               Vallaloggen kontaktas på info@vallaloggen.se
             </div>
           </div>
         </div>
       </Page>
    );
  }
}

export default TermsAndConditions;