import React, {Component} from 'react';
import Card from "./Card";
import {Button} from "grommet";

class Modal extends Component {
  cancel(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.onCancel && this.props.onCancel()
  }

  ok(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.onOk && this.props.onOk()
  }

  clickOutSide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.onClickOutSide && this.props.onClickOutSide()
  }

  render() {
    return (
       <div className="modal-container" onClick={(e) => this.clickOutSide(e)}>
         <div className="modal-content-container">
           <div className="modal-content" onClick={(e) => e.stopPropagation()}>
             <Card className={"modal-card " + this.props.cardClasses} style={{zIndex: "9999"}}>
               {this.props.children}
               <div className="modal-button-container">
                 {this.props.onCancel &&
                 <Button className="modal-button-cancel" label="Avbryt" onClick={(e) => this.cancel(e)}/>
                 }
                 {this.props.onOk &&
                 <Button className="modal-button-ok" label="OK" onClick={(e) => this.ok(e)}/>
                 }
               </div>
             </Card>
           </div>
         </div>
       </div>
    );
  }
}

export default Modal;