import React from 'react';
import {Redirect, Route} from "react-router-dom";
import {connect} from "react-redux";

const LoggedInRoute = ({component: Component, authentication, ...rest}) => {
  return (
     <Route
        {...rest}
        render={props => {
          if (authentication.isLoggedIn) {
            return <Component {...props} />;
          } else {
            return (
               <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: props.location
                    }
                  }}
               />
            );
          }
        }}
     />
  );
};

const mapStateToProps = state => ({
  authentication: state.authentication
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  }
};


export default connect(mapStateToProps, null, mergeProps)(LoggedInRoute);