import React, {Component} from 'react';
import {connect} from "react-redux";
import Modal from "../helperComponents/Modal";
import ContactMedias from "../ContactMedias";

class HandleUserPopUpComponent extends Component {

  closeFirstTimeUserModal() {
    this.props.dispatch(this.props.actions.setIsFirstTimeUser(false));
  }

  render() {
    const showFirstTimeLoginModal = this.props.authentication.isFirstTimeUser;
    return (
       <div>
         {showFirstTimeLoginModal &&
         <Modal
            onOk={() => this.closeFirstTimeUserModal()}
            onClickOutSide={() => this.closeFirstTimeUserModal()}
         >
           <FirstTimeUserInfo/>
         </Modal>
         }
       </div>
    );
  }
}

const mapStateToProps = state => ({
  authentication: state.authentication
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    actions: {...stateProps.authentication.actions},
    ...dispatchProps,
    ...ownProps
  }
};


export default connect(mapStateToProps, null, mergeProps)(HandleUserPopUpComponent);


const FirstTimeUserInfo = () => {
  return (
     <div>
       <h1>Välkommen till Vallaloggen!</h1>
       <p>Grattis till ett bra beslut! Nu kan du samla och utveckla din kunskap inom vallning på ett och samma
         ställe!</p>
       <p>Du kan enkelt skapa, hitta och bedöma rapporter från dig själv och andra.</p>
       <p>Har du vallarapporter på andra ställen? Till exempel i ett excel dokument eller i ett anteckningsblock? Skicka
         ett mail till Vallaloggen så hjälper vi till att lägga in dina rapporter här.</p>
       <p>Du är även välkommen att höra av dig med frågor, funderingar och förbättringsförslag</p>
       <p>Tillsammans ska vi göra Vallaloggen till den ultimata vallakamraten!</p>
       <div style={{
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         marginBottom: '32px'
       }}>
         <ContactMedias/>
       </div>
     </div>
  );
};

