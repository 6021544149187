import events from '../../actionEvents.js'
export default function ConditionModule({conditionsService}) {

  const baseState = {
    snowTypes: [],
    actions: {
      fetchSnowTypes
    }
  };

  return {
    reducer,
    actions: baseState.actions,
    _loadSnowTypes: (snowTypes) => baseState.snowTypes = snowTypes
  };

  function fetchSnowTypes() {
    return async (dispatch) => {
      const snowTypes = await conditionsService.fetchSnowTypes();
      dispatch(events.setSnowTypes(snowTypes))
    }
  }

  function reducer(state = baseState, action) {
    switch (action.type) {
      case 'SET_SNOW_TYPE':
        return {
          ...state,
          snowTypes: action.snowTypes
        };
      default:
        return state;
    }
  }
}