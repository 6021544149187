import React, {Component} from 'react';
import {errorEnums} from "../../../../text/texts";
import {truthyOrZero} from "../../../../utils/intUtils";
import Input from "../../../../app/components/helperComponents/input/Input";
import ErrorSpace from "../../../../app/components/helperComponents/text/ErrorSpace";
import DateTimeInput from "../../../../app/components/helperComponents/input/DateInput/DateTimeInput";
import Modal from "../../../../app/components/helperComponents/Modal";
import ConditionsSelect from "../../../../app/components/conditions/ConditionsSelect";
import PrimaryButton from "../../../../app/components/helperComponents/buttons/PrimaryButton";
import ClickWrapper from "../helperComponents/click/ClickWrapper";
import ProductPreview from "../../../../app/components/product/productPreview/ProductPreview";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProductSearchSelect from "../../../../app/components/product/ProductSearchSelect";
import RadioButtonInput from "../../../../app/components/helperComponents/input/RadioButtonInput";
import TextArea from "../../../../app/components/helperComponents/input/TextArea";

class ReportInput extends Component {
  state = {
    title: '',
    temperature: null,
    snowTypeId: null,
    products: [],
    glideRating: null,
    gripRating: null,
    dateTime: new Date(),
    description: '',
    titleError: null,
    ratingError: null,
    conditionError: null,
    showConditionSelectModal: false,
  }

  componentDidMount() {
    const prefill = this.props.prefillFromReport;
    if (!!prefill) this.setState({
      title: prefill.title,
      dateTime: new Date(prefill.skiingDate),
      temperature: prefill.temperature,
      snowTypeId: prefill.snowType,
      products: prefill.products,
      glideRating: prefill.glideRating,
      gripRating: prefill.gripRating,
      description: prefill.description
    })
  }

  setTitle(title) {
    this.setState({title})
  }

  setCondition(condition) {
    const newSnowTypeId = condition.snowType ? condition.snowType.id : null
    this.setState({temperature: condition.temperature, snowTypeId: newSnowTypeId})
  }

  appendProduct(product) {
    const productsCopy = [...this.state.products];
    productsCopy.push(product)
    this.setState({products: productsCopy});
  }

  removeProduct(product) {
    const productsCopy = [...this.state.products].filter(p => p.id !== product.id);
    this.setState({products: productsCopy});
  }

  rateGlide(rating) {
    this.setState({glideRating: rating})
  }


  rateGrip(rating) {
    this.setState({gripRating: rating})
  }

  setDateTime(dateTime) {
    this.setState({dateTime})
  }

  getTitleValidation() {
    const {title} = this.state;
    if (title.length < 1) return errorEnums.MISSING_REPORT_TITLE
    else if (title.length > 100) return errorEnums.REPORT_TITLE_TO_LONG
    else return null
  }

  getRatingValidation() {
    const {glideRating, gripRating} = this.state;
    if (!glideRating && !gripRating) return errorEnums.REPORT_MISSING_RATING
    else return null
  }

  getConditionValidation() {
    const {temperature, snowTypeId} = this.state;
    if (!truthyOrZero(temperature) || !snowTypeId) return errorEnums.REPORT_MISSING_CONDITION
    else if (temperature > 100 || temperature < -100) return errorEnums.INVALID_TEMPERATURE
  }

  validateAndSubmit() {
    const maybeTitleError = this.getTitleValidation()
    const maybeRatingError = this.getRatingValidation();
    const maybeConditionError = this.getConditionValidation();
    if (maybeTitleError) this.setState({titleError: maybeTitleError});
    if (maybeRatingError) this.setState({ratingError: maybeRatingError})
    if (maybeConditionError) this.setState({conditionError: maybeConditionError})
    if (maybeTitleError || maybeRatingError || maybeConditionError) {
      this.setState({titleError: maybeTitleError, ratingError: maybeRatingError, conditionError: maybeConditionError})
    } else {
      const reportCandidate = {
        title: this.state.title,
        skiingDate: this.state.dateTime.getTime(),// to fix
        temperature: this.state.temperature,
        snowType: this.state.snowTypeId,
        products: this.state.products.map(p => p.id),
        glideRating: this.state.glideRating,
        gripRating: this.state.gripRating,
        description: this.state.description
      }
      this.props.onDone(reportCandidate);
    }
  }

  setDescription(text) {
    this.setState({description: text});
  }

  showConditionSelectModal() {
    this.setState({showConditionSelectModal: true})
  }

  validateConditionsAndHideConditionSelectModal() {
    const maybeError = this.getConditionValidation()
    if (maybeError) {
      this.setState({conditionError: maybeError})
    } else {
      this.setState({showConditionSelectModal: false, conditionError: null})
    }
  }

  render() {
    const ratings = [
      {value: 'bad', text: 'Dåligt'},
      {value: 'good', text: 'Bra'},
      {value: 'very_good', text: 'Mycket bra'}
    ]
    return (
       <div className="create-report-container">
         <div className="create-report-title">
           <Input
              value={this.state.title}
              autoComplete="off"
              onChange={(e) => this.setTitle(e.target.value)}
              icon="tag"
              label="Vad vill du kalla rapporten?"/>
           <ErrorSpace errorEnum={this.state.titleError}/>
         </div>
         <div>
           <DateTimeInput
              onChange={(dateTime) => this.setDateTime(dateTime)}
              dateTime={this.state.dateTime}
              max={new Date()}
              label="När åkte du?"
           />
         </div>
         <div className="create-report-condition">
           {this.state.showConditionSelectModal
              ? <Modal onClickOutSide={() => this.validateConditionsAndHideConditionSelectModal()}>
                <div className="create-report-condition-modal">
                  <ConditionsSelect
                     initialSnowTypeId={this.state.snowTypeId}
                     initialTemperature={this.state.temperature}
                     onConditionChange={(newCondition) => this.setCondition(newCondition)}
                     temperatureLabel="Vad var det för temperatur?"
                     snowTypeLabel="Hur var snön?"
                     allowDeselectSnowType={false}
                  />
                  <ErrorSpace errorEnum={this.state.conditionError}/>
                  <PrimaryButton label="OK" onClick={() => this.validateConditionsAndHideConditionSelectModal()}/>
                </div>
              </Modal>
              : <ClickWrapper onClick={() => this.showConditionSelectModal()}>
                <Input
                   label="Hur var förutsättningarna?"
                   actiontext="Lägg till"
                   disabled
                   value={this.getConditionString()}
                   className="create-report-condition-select-placeholder"
                   onChange={() => ""}
                />
              </ClickWrapper>
           }
           <ErrorSpace errorEnum={this.state.conditionError}/>
         </div>
         <div className="create-report-products">
           {this.state.products.map(product =>
              <div key={product.id} className="create-report-product-added">
                <div className="create-report-product">
                  <ProductPreview product={product}/>
                </div>
                <div className="create-report-remove-wax" onClick={() => this.removeProduct(product)}>
                  <FontAwesomeIcon icon="times" size="lg"/>
                </div>
              </div>
           )}
         </div>
         <div>
           <ProductSearchSelect
              onSelect={(wax) => this.appendProduct(wax)}
              ignoreIds={this.state.products.map(p => p.id)}
           />
         </div>
         <div className="create-report-ratings">
           <div className="create-report-glide-rating create-report-rating-row">
             <RadioButtonInput
                title="Hur var glidet?"
                options={ratings}
                selectedOption={this.state.glideRating}
                onSelect={(rating) => this.rateGlide(rating)}/>
           </div>
           <div className="create-report-grip-rating create-report-rating-row">
             <RadioButtonInput
                title="Hur var fästet?"
                options={ratings}
                selectedOption={this.state.gripRating}
                onSelect={(rating) => this.rateGrip(rating)}/>
           </div>
           <ErrorSpace errorEnum={this.state.ratingError}/>
         </div>
         <div>
           <TextArea
              label="Berätta mer"
              value={this.state.description}
              onChange={(text) => this.setDescription(text)}
              rows={3}/>
         </div>
         <div className="create-report-button-container">
           <PrimaryButton onClick={() => this.validateAndSubmit()} label={this.props.confirmText}/>
         </div>

       </div>
    );
  }

  getConditionString() {
    const temperature = truthyOrZero(this.state.temperature) ? this.state.temperature.toString() + '°C' : ''
    const snowType = this.state.snowTypeId
       ? this.props.snowTypes.find(s => s.id === this.state.snowTypeId).snowType
       : ''

    const maybeAnd = temperature && snowType ? ', ' : ''

    return temperature + maybeAnd + snowType
  }
}

export default ReportInput;