import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import blueLogo from "../../assets/blue-logo.svg";

class TopMenu extends Component {
  render() {
    const isLoggedIn = this.props.authentication.isLoggedIn;
    const pathname = this.props.location.pathname;
    return (
       <div className="top-menu">
         <div className="top-menu-content">
           <div className="top-menu-logo-container" onClick={() => window.location.href = "/"}>
             <img src={blueLogo} className="topmenu-logo" alt="Vallaloggen logo"/>
           </div>
           <div className="top-menu-action-items">
             <TopMenuItem
                icon={["far", "lightbulb"]}
                text="Förslag"
                linkTo='/recommendation'
                active={pathname === '/recommendation'}/>
             <TopMenuItem
                icon={["far", "plus-square"]} text="Ny rapport"
                linkTo={isLoggedIn ? '/create/report' : '/login?redirect=/create/report'}
                active={pathname === '/create/report'}/>
             <TopMenuItem
                icon={["far", "user"]} text="Mitt konto"
                linkTo={isLoggedIn ? '/user' : '/login'}
                active={pathname === '/user'}/>
           </div>
         </div>
       </div>
    );
  }
}

const mapStateToProps = state => ({
  authentication: state.authentication
});

export default withRouter(connect(mapStateToProps)(TopMenu));

class TopMenuItem extends Component {

  state = {
    width: 0,
    height: 0
  }

  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({width: window.innerWidth, height: window.innerHeight});
  }

  render() {
    const containerClassNames = this.props.active ? "top-menu-item-container top-menu-item-active" : "top-menu-item-container";
    return (
       <div className={containerClassNames}>
         <Link to={this.props.linkTo} className="top-menu-link">
           <div className="top-menu-item">
             <FontAwesomeIcon size="lg" icon={this.props.icon}/>
             <p className="top-menu-item-text">{this.props.text}</p>
           </div>
         </Link>

       </div>
    )
  }
}
