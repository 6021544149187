import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import colors from "../../../../style/colors";

export const emojiTypes = {
  VERY_HAPPY: '😀',
  HAPPY: '😊',
  SAD: '😕'
}

export const Emoji = props => {

  if (props.symbol === emojiTypes.VERY_HAPPY) return (
     <FontAwesomeIcon icon={["far", "laugh"]} color={colors.success50}/>
  ); else if (props.symbol === emojiTypes.HAPPY) return (
     <FontAwesomeIcon icon={["far", "smile-beam"]} color={colors.success50}/>
  ); else return <div>Hej</div>


  // return <span
  //    className="vallaloggen-emoji"
  //    role="img"
  //    aria-label={props.label ? props.label : ""}
  //    aria-hidden={props.label ? "false" : "true"}
  // >
  //       {props.symbol}
  //   </span>
};
