export const errorEnums = {
  INVALID_TEMPERATURE: "INVALID_TEMPERATURE",
  MISSING_CONDITION: "MISSING_CONDITION",
  MISSING_REPORT_TITLE: 'MISSING_REPORT_TITLE',
  REPORT_TITLE_TO_LONG: "REPORT_TITLE_TO_LONG",
  REPORT_MISSING_RATING: "REPORT_MISSING_RATING",
  REPORT_MISSING_CONDITION: "REPORT_MISSING_CONDITION"
}

const errorTexts = {
  INVALID_TEMPERATURE: "Ogiltig temperatur.",
  MISSING_CONDITION: "Vänligen välj ett före för att vallaloggen ska kunna rekommendera produkter.",
  MISSING_REPORT_TITLE: 'Rapporten måste ha ett namn',
  REPORT_TITLE_TO_LONG: 'Rapporten har för långt namn. Max 100 tecken',
  REPORT_MISSING_RATING: 'Ange minst ett betyg.',
  REPORT_MISSING_CONDITION: 'Ange både temperatur och snötyp.'
}

export function errorTextByType(errorEnum) {
  return errorTexts[errorEnum]
}