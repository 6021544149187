import React from 'react';
import './style.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Input = (props) => {
  return (
     <div className="vallaloggen-input">
       <div className="vallaloggen-label-input">
         <label htmlFor={`vallaloggen-input-${props.label}`}>{props.label}</label>
       </div>
       <div className="vallaloggen-input-container">
         <input id={`vallaloggen-input-${props.label}`} {...props}/>
         <div className="vallaloggen-input-icon-container">
           {props.icon && <FontAwesomeIcon icon={props.icon} color={props.iconcolor || "#5c5c5c"}/>}
           {props.actiontext && <p className="vallaloggen-input-action-text">{props.actiontext}</p>}
         </div>
       </div>
     </div>
  );
};

export default Input;