import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './texts.scss'

const Header3 = (props) => {
  const maybeIcon = props.icon &&
     <span className="section-header-icon">
         <FontAwesomeIcon icon={props.icon} color={props.iconColor}/>
       </span>

  return (
     <span>
       <h3 className="vallaloggen-header-3">
         {maybeIcon && maybeIcon}
         {props.text}
       </h3>
     </span>
  );
};

export default Header3;