import React, {Component} from 'react';
import UserWaxBoxComponent from "../../components/product/user/UserWaxBoxComponent";
import Page from "../Page";
import Header1 from "../../components/helperComponents/text/Header1";
import InformationField from "../../components/helperComponents/text/PageInformation";

class UserWaxBoxPage extends Component {
  render() {
    return (
       <Page>
         <Header1 title="Min vallalåda"/>
         <div className="user-wax-box-information-section">
           <InformationField className="user-wax-box-information-section-text">
             <p>Här kan du lägga till de vallor och produkter du har tillgång till. På så vis kommer du åt dem
               snabbare och kan få personliga rekommendationer.</p>
           </InformationField>
         </div>
         <UserWaxBoxComponent history={this.props.history}/>
       </Page>
    );
  }
}

export default UserWaxBoxPage;