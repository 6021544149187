export default function UserWaxBoxService({httpClient, authenticationService}) {

  return {
    addWax,
    fetchForUser
  };

  async function addWax(waxId) {
    const result = await httpClient.put(
       '/wax-box',
       {waxId},
       {headers: {'Authorization': `Bearer ${authenticationService.getToken()}`}}
    );
    return result.data
  }

  async function fetchForUser() {
    const result = await httpClient.get(
       '/wax-box',
       {headers: {'Authorization': `Bearer ${authenticationService.getToken()}`}}
    );
    return result.data
  }

}