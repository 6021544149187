import ReactGA from "react-ga";
import {hotjar} from 'react-hotjar';

export default {
  initialize,
  trackPageView,
  setUserId,
  searchWaxEvent,
  searchReportEvent,
  loggedInWithGoogle,
  startedToLoginWithEmail,
  loggedInWithEmail,
  faildLoginWitEmail,
  facebookClicked,
  instagramClicked,
  startedCreatingReport,
  finishedCreatingReport,
  clickedRelatedReports,
  clickedOnReport,
  startEditReport,
  addedWaxToWaxBox
}

const hotjarIds = {
  development: null,
  production: '2060027',
  testEnvironment: '2012376'
}

function initialize() {
  if (shouldTrack()) {
    console.log('Initializing GA')
    ReactGA.initialize('UA-153420949-1');
    ReactGA.pageview('/');
  }
  const maybeHotjarId = hotjarIds[process.env.REACT_APP_ENV_TYPE];
  if (maybeHotjarId) {
    console.log('Initializing hotjar')
    hotjar.initialize(maybeHotjarId)
  }
}

// Update the user's current page
// Record a pageview for the given page
function trackPageView(path) {
  if (shouldTrack()) {
    ReactGA.set({page: path});
    ReactGA.pageview(path);
  }
}

function setUserId(userId) {
  shouldTrack() && ReactGA.set({userId: userId})
}

function searchWaxEvent(advancedSnowTypes) {
  if (shouldTrack()) {
    ReactGA.event({
      category: 'Search wax',
      action: 'Search wax with advanced snowTypes: ' + advancedSnowTypes,
      label: ''
    })
  }
}

function searchReportEvent() {
  shouldTrack() && ReactGA.event({
    category: 'Search report',
    action: 'Searched wax report',
    label: ''
  })
}

function loggedInWithGoogle(extra) {
  shouldTrack() && ReactGA.event({
    category: 'Logged in',
    action: 'Logged in with google',
    label: extra
  })
}

function startedToLoginWithEmail(extra) {
  shouldTrack() && ReactGA.event({
    category: 'Log in with email started',
    action: 'User started to login with email',
    label: extra
  });
}

function loggedInWithEmail(extra) {
  shouldTrack() && ReactGA.event({
    category: 'Logged in',
    action: 'Logged in with email',
    label: extra
  })
}

function faildLoginWitEmail(actionText) {
  shouldTrack() && ReactGA.event({
    category: 'Login failed',
    action: actionText,
  })
}

function facebookClicked() {
  shouldTrack() && ReactGA.event({
    category: 'Facebook clicked',
    action: 'User navigated to vallaloggen facebook'
  })
}

function instagramClicked() {
  shouldTrack() && ReactGA.event({
    category: 'Instagram clicked',
    action: 'User navigated to vallaloggen instagram'
  })
}

function startedCreatingReport() {
  shouldTrack() && ReactGA.event({
    category: "Create report",
    action: "User started to create report"
  });
}

function finishedCreatingReport() {
  shouldTrack() && ReactGA.event({
    category: "Create report",
    action: "User completed creating report"
  });
}

function clickedRelatedReports() {
  shouldTrack() && ReactGA.event({
    category: "Viewed related reports",
    action: "User clicked on related reports for a wax"
  });
}

function clickedOnReport() {
  shouldTrack() && ReactGA.event({
    category: "Viewed report details",
    action: "User viewed report details"
  });
}

function startEditReport() {
  shouldTrack() && ReactGA.event({
    category: "Edit report",
    action: "User edited report"
  });
}


function addedWaxToWaxBox(waxName) {
  shouldTrack() && ReactGA.event({
    category: "Added wax to waxbox",
    action: `User added ${waxName} to its waxbox`
  });
}

function shouldTrack() {
  return process.env.REACT_APP_ENABLE_ANALYTICS === 'true'
}
