import React from 'react';
import './_page-size.scss'

const ViewContent = (props) => {
  return (
     <div className="vallaloggen-view-container">
       <div className="vallaloggen-view-content">
         {props.children}
       </div>
     </div>
  );
};

export default ViewContent;