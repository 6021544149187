import React, {Component} from 'react';
import GoogleLogin from "react-google-login";
import {connect} from "react-redux";
import EmailLoginComponent from "./EmailLoginComponent";
import {Link} from "react-router-dom";
import analytics from "../../../../analytics/analytics";
import OutlinedButton from "../../helperComponents/buttons/OutlinedButton";

class LoginComponent extends Component {

  state = {
    option: ''
  };

  async responseGoogle(response) {
    const tokenId = response.tokenId;
    analytics.loggedInWithGoogle('Path: ' + window.location.pathname);
    this.props.dispatch(this.props.actions.loginUser({tokenId: tokenId}));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.authentication.isLoggedIn) {
      this.props.onSuccess();
    }
  }

  render() {
    return (<div className="login-component-container">
      {this.state.option !== 'email' && <div className="login-component-chose-option">
        <div className="login-instruction-text-container">
          <p>Genom att logga in eller registrera dig godkänner du också <Link
             to="/privacy-policy">Integritetspolicyn</Link> och <Link to="/terms-and-conditions">Användarvilkoren</Link>.
          </p>
        </div>
        <div className="login-component-third-party">
          {this.props.googleClientId ? <GoogleLogin
             className="google-login-button-vallaloggen"
             clientId={this.props.googleClientId || ""}
             buttonText="LOGGA IN MED GOOGLE"
             onSuccess={(response) => this.responseGoogle(response)}
             onFailure={(failure) => this.props.onFailure(failure)}
             render={renderProps => (
                <OutlinedButton
                   type="primary"
                   image={googleLogo}
                   label="LOGGA IN MED GOOGLE"
                   disabled={renderProps.disabled}
                   onClick={renderProps.onClick}/>
             )}
          /> : null}
        </div>
        <div className="login-component-email-button-container">
          <OutlinedButton
             type="primary"
             icon={"envelope"}
             label="LOGGA IN MED E-POST"
             onClick={() => this.useEmailOption()}/>
        </div>
      </div>}
      {this.state.option === 'email' && <div className="login-component-email-container">
        <EmailLoginComponent/>
      </div>}
    </div>);
  }

  useEmailOption() {
    this.setState({option: 'email'})
  }
}

const mapStateToProps = state => ({
  googleClientId: state.clientInfo.googleClientId,
  authentication: state.authentication
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    actions: {...stateProps.authentication.actions},
    ...dispatchProps,
    ...ownProps
  }
};

export default connect(mapStateToProps, null, mergeProps)(LoginComponent);


const googleLogo = <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
  <g fill="#000" fillRule="evenodd">
    <path
       d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
       fill="#EA4335"></path>
    <path
       d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
       fill="#4285F4"></path>
    <path
       d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
       fill="#FBBC05"></path>
    <path
       d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
       fill="#34A853"></path>
    <path fill="none" d="M0 0h18v18H0z"></path>
  </g>
</svg>