import React from 'react';
import Button from "./Button";
import './buttons.scss';

const OutlinedButton = (props) => {
  return (
     <Button {...props} className={`vallaloggen-button-outlined vallaloggen-button-outlined-${props.type}`}/>
  );
};

export default OutlinedButton;