import {combineReducers} from 'redux';

export default function RootReducer(reducers) {
  return {
    reducers: combineReducers({
      clientInfo: reducers.clientInfoReducer,
      authentication: reducers.authenticationReducer,
      user: reducers.userReducer,
      condition: reducers.conditionsReducer,
      product: reducers.productReducer,
      reports: reducers.reportsReducer,
      admin: reducers.adminReducer,
      settings: reducers.settingsReducer
    })
  }
}