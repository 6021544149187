import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormClose} from "grommet-icons";


class CookieBar extends Component {
  render() {
    return (
       <div>
         {this.props.clientInfo.hideCookieBar ? null : <div className="cookie-bar">
           <div className="cookie-bar-content">
             <div>
               <p>Genom att använda vallaloggen tillåter du användandet av cookies.
                 Läs mer i Vallaloggens <a href="/privacy-policy">Integritetspolicy</a>&nbsp;
                 och i <a href="/terms-and-conditions">Användarvilkoren</a></p>
             </div>
             <div className="cookie-bar-close-container">
               <FormClose onClick={() => this.hideCookieBar()} color="#ffffff"/>
             </div>
           </div>
         </div>}
       </div>
    );
  }

  hideCookieBar() {
    this.props.dispatch(this.props.actions.hideCookieBar())
  }
}


const mapStateToProps = state => ({
  clientInfo: state.clientInfo
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    actions: {...stateProps.clientInfo.actions},
    ...dispatchProps,
    ...ownProps
  }
};


export default connect(mapStateToProps, null, mergeProps)(CookieBar);