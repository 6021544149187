import React, {Component} from 'react';
import TopMenu from "../../components/TopMenu";
import Card from "../../components/helperComponents/Card";
import ContactMedias from "../../components/ContactMedias";

class AboutVallaloggen extends Component {
  render() {
    return (
       <div>
         <div>
           <TopMenu/>
           <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
             <div style={{width: "80vw"}}>
               <Card>
                 <div
                    style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: 'column'}}>
                   <h1>Om vallaloggen</h1>
                   <div>

                     <p style={{textAlign: 'left'}}>Vallaloggen är ett projekt som ämnar göra vallning enklare för såväl
                       elit som motionär genom att
                       sprida och sammla kunskap på ett effektivt sätt.</p>

                     <p>På vallaloggen kan du enkelt hantera dina vallarapporter, söka på vallor och hitta tips på vad
                       andra har vallat med i olika fören.</p>

                     <p>Kort och gott är Vallaloggen en samlingsplats och ett forum för dina vallakunskaper.</p>
                   </div>
                 </div>
                 <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                   <ContactMedias/>
                 </div>
               </Card>
             </div>
           </div>
         </div>
       </div>
    );
  }
}

export default AboutVallaloggen;