import React from 'react';
import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faCheckSquare,
  faCoffee,
  faSnowflake,
  faEdit,
  faCircle,
  faThermometerHalf,
  faThermometerQuarter,
  faTimes,
  faInfoCircle,
  faFilter,
  faBriefcase,
  faBookOpen,
  faEnvelope,
  faKey,
  faSearch,
  faTag,
  faCheck,
  faStar,
  faCaretLeft
} from '@fortawesome/free-solid-svg-icons'
import {
  faThumbsUp,
  far,
  faLightbulb,
  faPlusSquare,
  faUser,
  faCalendar,
  faLaugh,
  faSmileBeam,
   faChartBar
} from '@fortawesome/free-regular-svg-icons'
import {faGoogle} from '@fortawesome/free-brands-svg-icons'

library.add(
   far,
   faCheckSquare,
   faCoffee,
   faSnowflake,
   faEdit,
   faCircle,
   faThermometerHalf,
   faThermometerQuarter,
   faThumbsUp,
   faTimes,
   faLightbulb,
   faPlusSquare,
   faUser,
   faInfoCircle,
   faFilter,
   faBriefcase,
   faBookOpen,
   faEnvelope,
   faGoogle,
   faKey,
   faSearch,
   faTag,
   faCheck,
   faCalendar,
   faStar,
   faCaretLeft,
   faLaugh,
   faSmileBeam,
   faChartBar
)

const EnableFontAwesomeIcons = (props) => {

  return (
     <>{props.children}</>
  )
};

export default EnableFontAwesomeIcons;

// https://github.com/FortAwesome/react-fontawesome#learn-about-our-new-svg-implementation
// https://fontawesome.com/icons?d=gallery&m=free