import React from 'react';
import './style.scss'

const TextArea = (props) => {
  return (
     <div className="vallaloggen-input">
       <div className="vallaloggen-label-input">
         <label htmlFor={`vallaloggen-textarea-${props.label}`}>{props.label}</label>
       </div>
       <div className="vallaloggen-input-container">
       <textarea
          id={`vallaloggen-textarea-${props.label}`}
          className="vallaloggen-text-area"
          value={props.value || ''}
          rows={props.rows || 2}
          onChange={(e) => props.onChange(e.target.value)}
       />
       </div>
     </div>
  );
};

export default TextArea;