import events from '../../actionEvents.js'
import analytics from "../../../analytics/analytics";

export default function UserModule({authenticationService, userService}) {

  const baseState = {
    user: null,
    fetchUserStatus: 'none',
    userDeleted: false,
    actions: {
      fetchUser,
      deleteUser
    }
  };

  return {
    reducer,
    _loadUser: user => baseState.user = user
  };

  function fetchUser() {
    return async (dispatch) => {
      const isLoggedIn = authenticationService.isLoggedIn();
      if (isLoggedIn) {
        dispatch(events.setfetchUserStatus('fetching'));
        dispatch(events.setLoggedIn(true));
        const user = await userService.getUser();
        analytics.setUserId(user.id);
        dispatch(events.setUser(user));
        dispatch(events.setfetchUserStatus('done'));
      } else authenticationService.logOut();
    }
  }

  function deleteUser() {
    return async (dispatch) => {
      if (await userService.deleteUser()) {
        authenticationService.logOut();
        dispatch(events.setUserDeleted(true))
      }
    }
  }

  function reducer(state = baseState, action) {
    switch (action.type) {
      case 'SET_USER':
        return {...state, user: action.user};
      case 'SET_IS_FETCHING_USER':
        return {...state, fetchUserStatus: action.fetchUserStatus};
      case 'SET_USER_DELETED':
        return {...state, userDeleted: action.userDeleted};
      default:
        return state
    }
  }
}