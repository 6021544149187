import React from 'react';
import "./lists.scss";

const LinkedBulletList = (props) => {
  return (
     <div className="vallaloggen-linked-bullet-list">
       <ul>
         {props.items.map(item => {
           return <LinkedBulletListItem key={item} text={item}/>
         })}
       </ul>

     </div>
  );
};

export default LinkedBulletList;

const LinkedBulletListItem = (props) => {
  return (
     <li>
       <div className="linked-bullet-list-item">{props.text}</div>
     </li>
  );
};
