import React from 'react';
import './style.scss'
const CheckboxInput = (props) => {

  const {isChecked, label} = props

  return (
     <div className="vallaloggen-input-container vallaloggen-checkbox-container">
       <label htmlFor={`vallaloggen-checkbox-${label}`} className="vallaloggen-checkbox-label">
         <input
            className="vallaloggen-checkbox"
            type={"checkbox"}
            id={`vallaloggen-checkbox-${label}`}
            checked={isChecked}
            onChange={(e) => props.onChange(!isChecked)}/>
         {label}
       </label>
     </div>
  );
};

export default CheckboxInput;