import React, {Component} from 'react';
import ConditionsSelect from "../../components/conditions/ConditionsSelect";
import ErrorSpace from "../../components/helperComponents/text/ErrorSpace";
import PrimaryButton from "../../components/helperComponents/buttons/PrimaryButton";
import {errorEnums} from "../../../text/texts";

class SelectConditionStep extends Component {
  state = {
    error: '',
    condition: null,
  }

  setCondition(condition) {
    this.setState({condition, error: ''});
  }

  proceed() {
    if (this.state.condition) {
      this.props.onProceed(this.state.condition)
    } else this.setState({error: errorEnums.MISSING_CONDITION})
  }

  render() {
    return (
       <div>
         <ConditionsSelect
            onConditionChange={(condition) => this.setCondition(condition)}
            temperatureLabel={"Hur kallt blir det?"}
            snowTypeLabel="Vad blir det för snö?"
            allowDeselectSnowType={true}/>
         <ErrorSpace errorEnum={this.state.error}/>
         <PrimaryButton onClick={() => this.proceed()} label="Fortsätt"/>
       </div>
    );
  }
}

export default SelectConditionStep;

// it provides the condition