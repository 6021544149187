import React, {Component} from 'react';
import './_tabs.scss';

class Tabs extends Component {

  setCurrentTab(option, index) {
    this.props.onChange && this.props.onChange({title: option.props.title, index: index})
  }

  render() {
    return (
       <div className="vallaloggen-tabs-container">
         <div className="vallaloggen-tabs">
           {this.props.children.map((option, index) => {
             const active = option.props.title === this.props.activeChildTitle
             return <div
                onClick={() => this.setCurrentTab(option, index)}
                className={`vallaloggen-tab ${active && 'vallaloggen-tab-active'}`}
                key={option.props.title}>
               {option.props.title}
             </div>
           })}
         </div>
         <div>
           <div>
             {this.props.children.map(option => {
               const active = option.props.title === this.props.activeChildTitle
               if (active) {
                 return <div
                    className={`vallaloggen-tab-content`}
                    key={option.props.title}>
                   {option}
                 </div>
               } else return <div key={option.props.title}/>
             })}
           </div>
         </div>
       </div>
    );
  }
}

const Tab = (props) => {
  return (
     <div>
       {props.children}
     </div>
  );
};


export {Tabs, Tab}

