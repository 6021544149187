import events from '../../actionEvents.js';

export default function ProductModule({productService, waxBoxService}) {

  const baseState = {
    actions: {
      fetchWaxProducts,
      searchProductsByText,
      fetchWaxProductsByIds, // todo maybe remove when implement report detials
      fetchProductDetails,
      setSearchResults,
      searchWaxProducts,
      fetchWaxSubTypes,
      fetchUserWaxBox,
      addWaxToWaxBox
    },
    waxProducts: [],
    waxTypes: ["glider", "kickwax", "riller"],
    waxSubTypes: [],
    searchResult: [],
    recommendationResults: [],
    isFetchingRecommendationResults: false,
    userWaxBoxItems: [],
    isFetchingWaxProducts: false,
    currentProductDetails: null,
    waxBrands: ["Skigo", "Swix", "Rex", "Rode", "Start", "Toko", "Star", "Zipps", "Vauhti", "Red Creek"]
  };

  return {
    reducer,
    actions: baseState.actions,
    _loadWaxSubTypes: (subTypes) => baseState.waxSubTypes = subTypes
  };

  // Admin only
  function fetchWaxProducts() {
    return async (dispatch) => {
      const products = await productService.fetchWaxProducts();
      dispatch(events.setWaxProducts(products));
    }
  }

  function fetchWaxProductsByIds(ids) {
    return async (dispatch, getState) => {
      const existingProducts = getState().product.waxProducts;
      const idsToFetch = ids.filter(id => !existingProducts.find(p => p.id === id));
      if (idsToFetch.length) {
        const oldWaxes = getState().product.waxProducts;
        dispatch(events.setIsFetchingWaxProducts(true));
        const products = await productService.fetchWaxProductsByIds(idsToFetch);
        dispatch(events.setWaxProducts([...oldWaxes, ...getDeltaOfWaxes(oldWaxes, products)]));
      }
      dispatch(events.setIsFetchingWaxProducts(false));
    }
  }

  function fetchProductDetails(id) {
    return async (dispatch) => {
      dispatch(events.setIsFetchingWaxProducts(true));
      const productDetails = await productService.fetchProductDetails(id)
      dispatch(events.setIsFetchingWaxProducts(false));
      dispatch(events.setCurrentProductDetails(productDetails))
    }
  }

  function searchProductsByText(query) {
    return async (dispatch, getState) => {
      if (query) {
        const oldWaxes = getState().product.waxProducts;
        const result = await productService.searchProductsByText(query);
        dispatch(events.setWaxProducts([...oldWaxes, ...getDeltaOfWaxes(oldWaxes, result)]));
        dispatch(events.setWaxSearchResult(result));

        // TODO remove when all search stuff is implemented on the backend
        // const queryParts = query.split(/[ -]+/).map(part => part.toLowerCase());
        // const result = products.filter(p => {
        //   const waxNameParts = p.waxName.split(/[ -]+/).map(part => part.toLowerCase());
        //   return compareStrings(p.brand, query)
        //      || compareStrings(p.waxName, query) // could be removed
        //      || compareStrings(`${p.brand} ${p.waxName}`, query) // could be removed
        //      || compareStrings(`${p.waxName} ${p.brand}`, query)
        //      || compareStrings(p.waxSubType, query)
        //      || compareStrings(`${p.brand} ${p.waxSubType}`, query)
        //      || compareStrings(`${p.waxSubType} ${p.brand}`, query)
        //      || queryParts.every(part => waxNameParts.includes(part) || part === p.brand)
        //      || (compareStrings(query, p.brand) && queryParts.some(part => waxNameParts.includes(part) || part === p.brand))
        // });
      } else {
        dispatch(events.setWaxSearchResult([]));
      }
    }
  }

  function searchWaxProducts(searchQueryObject) {
    return async (dispatch, getState) => {
      const oldWaxes = getState().product.waxProducts;
      dispatch(events.setIsFetchingRecommendationResults(true))
      try {
        const foundWaxes = await productService.searchWaxProducts(searchQueryObject);
        dispatch(events.setWaxRecommendationsResult(foundWaxes));
        dispatch(events.setIsFetchingRecommendationResults(false))
        dispatch(events.setWaxProducts([...oldWaxes, ...getDeltaOfWaxes(oldWaxes, foundWaxes.map(w => w.wax))]));
      } catch {
        dispatch(events.setIsFetchingRecommendationResults(false))
        dispatch(events.setWaxRecommendationsResult([]));
      }
    }
  }


  function setSearchResults(results) {
    return async (dispatch) => {
      dispatch(events.setWaxSearchResult(results));
    }
  }

  function fetchWaxSubTypes() {
    return async (dispatch) => {
      const waxSubTypes = await productService.fetchWaxSubTypes();
      await dispatch(events.setWaxSubTypes(waxSubTypes))
    }
  }

  function addWaxToWaxBox(wax) {
    return async (dispatch, getState) => {
      const updatedWaxBox = await waxBoxService.addWax(wax.id);
      const waxes = waxesToWaxModels(updatedWaxBox.waxes, getState());
      dispatch(events.setUserWaxBox({waxes}));
    }
  }

  function fetchUserWaxBox() {
    return async (dispatch, getState) => {
      const userWaxBox = await waxBoxService.fetchForUser();
      const waxes = waxesToWaxModels(userWaxBox.waxes, getState());
      dispatch(events.setUserWaxBox({waxes}));
    }
  }

  function getDeltaOfWaxes(oldWaxes, newWaxes) {
    return newWaxes.filter(newWax => !oldWaxes.some(oldWax => oldWax.id === newWax.id));
  }

  function waxesToWaxModels(waxes, state) {
    const waxSubTypes = state.product.waxSubTypes;
    const snowTypes = state.condition.snowTypes;
    return waxes.map(w => ({
      ...w,
      waxSubType: waxSubTypes.find(ws => w.waxSubTypeId === ws.id).waxSubTypeName,
      conditions: w.conditions.map(c => ({...c, snowType: snowTypes.find(s => c.snowTypeId === s.id).snowType}))
    }));
  }

  function reducer(state = baseState, action) {
    switch (action.type) {
      case 'SET_WAX_PRODUCTS':
        return {
          ...state,
          waxProducts: action.waxProducts
        };
      case 'APPEND_WAX_PRODUCTS':
        return {
          ...state,
          waxProducts: [...state.waxProducts, ...action.waxProducts]
        };
      case 'SET_WAX_SEARCH_RESULT':
        return {
          ...state,
          searchResult: action.waxProducts
        };
      case 'SET_WAX_RECOMMENDATIONS_RESULT':
        return {
          ...state,
          recommendationResults: action.recommendations
        }
      case 'SET_PRODUCT_DETAILS':
        return {
          ...state,
          currentProductDetails: action.productDetails
        }
      case 'SET_IS_FETCHING_WAX_PRODUCTS':
        return {
          ...state,
          isFetchingWaxProducts: action.isFetchingWaxProducts
        };
      case 'SET_IS_FETCHING_RECOMMENDATION_RESULTS':
        return {
          ...state,
          isFetchingRecommendationResults: action.isFetching
        };
      case 'SET_WAX_SUB_TYPES':
        return {
          ...state,
          waxSubTypes: action.waxSubTypes
        };
      case 'SET_USER_WAX_BOX':
        return {
          ...state,
          userWaxBoxItems: action.userWaxBox.waxes
        };
      default:
        return state
    }
  }
}