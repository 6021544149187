import React from 'react';
import './_click.scss'

const ClickWrapper = (props) => {
  return (
     <div className={"vallaloggen-clickable " + props.extraClasses} onClick={() => props.onClick()}>
       {props.children}
     </div>
  );
};

export default ClickWrapper;