import React, {Component} from 'react';
import {FormClose} from "grommet-icons";

class ListItem extends Component {

  state = {
    showDetails: false
  };

  showDetails() {
    if (this.props.details) {
      this.setState({showDetails: true})
    }
  }

  closeDetails(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({showDetails: false})

  }

  render() {
    return (
       <div className="admin-list-item" onClick={() => this.showDetails()}>
         {this.props.title}
         {this.state.showDetails && <div>
           <div onClick={(e) => this.closeDetails(e)}>
             <FormClose/>
           </div>
           <pre>
             {JSON.stringify(this.props.details, null, 4)}
           </pre>
         </div>}
       </div>
    );
  }
}

export default ListItem;