import {removeBlanks} from "../../utils/objectUtils";
import actions from './addData/adminActionEvents'

export default function AdminAddDataModule({adminAddDataService}) {

  const baseState = {
    actions: {
      createSnowType,
      createWaxSubtype,
      createWax,
      fetchBrands,
      calculateWaxScores,
      updateWax
    },
    snowTypeResult: {},
    waxSubTypeResult: {},
    waxResult: {},
    waxTypes: ["glider", "kickwax", "riller"],
    waxBrands: []
  };

  return {
    reducer
  };

  function createSnowType(snowTypeInfo) {
    return async (dispatch, getState) => {
      const cleanSnowType = removeBlanks(snowTypeInfo);
      await adminAddDataService.createSnowType(cleanSnowType)
         .then(newSnowType => {
           dispatch(actions.setSnowTypeResult(newSnowType));
           dispatch(getState().condition.actions.fetchSnowTypes());
         })
         .catch(error => dispatch(actions.setSnowTypeResult(error.response.data)));
    }
  }

  function createWaxSubtype(subTypeInfo) {
    return async (dispatch, getState) => {
      const cleanWaxSubType = removeBlanks(subTypeInfo);
      await adminAddDataService.createWaxSubType(cleanWaxSubType)
         .then(newWaxSubType => {
           dispatch(actions.setWaxSubTypeResult(newWaxSubType));
           dispatch(getState().product.actions.fetchWaxSubTypes());
         })
         .catch(error => dispatch(actions.setWaxSubTypeResult(error.response.data)));
    }
  }

  function createWax(waxInfo) {
    return async (dispatch, getState) => {
      const cleanWax = removeBlanks(waxInfo);
      await adminAddDataService.createWax(cleanWax)
         .then(newWax => {
           dispatch(actions.setWaxResult(newWax));
           dispatch(getState().product.actions.fetchWaxProducts());
         })
         .catch(error => dispatch(actions.setWaxResult(error.response.data)));
    }
  }

  function fetchBrands() {
    return async (dispatch) => {
      adminAddDataService.fetchBrands().then(brands => {
        dispatch(actions.setWaxBrands(brands))
      })
    }
  }

  function calculateWaxScores() {
    return async (dispatch) => {
      adminAddDataService.calculateWaxScores()
    }
  }

  function updateWax(wax) {
    return async (dispatch) => {
      adminAddDataService.updateWax(wax)
    }
  }

  function reducer(state = baseState, action) {
    switch (action.type) {
      case 'SET_SNOW_TYPE_RESULT':
        return {
          ...state,
          snowTypeResult: action.result
        };
      case 'SET_WAX_SUB_TYPE_RESULT':
        return {
          ...state,
          waxSubTypeResult: action.result
        };
      case 'SET_WAX_RESULT':
        return {
          ...state,
          waxResult: action.result
        };
      case 'SET_WAX_BRANDS':
        return {
          ...state,
          waxBrands: action.result
        };
      default:
        return state;
    }
  }
}