import React from 'react';
import ProductPreview from "../components/product/productPreview/ProductPreview.js";
import Header3 from "../components/helperComponents/text/Header3";
import colors from '../../style/colors.js'
import notFoundImage from '../../assets/mountains.svg'
import './_results.scss';

const WaxRecommendationResults = (props) => {
  return (
     <div className="wax-recommendation-result-section">
       <div className="wax-recommendation-result-content">
         <div>
           <div>
             <Header3 icon={["far", "thumbs-up"]} iconColor={colors.success50} text="Förslag"/>
           </div>
           <div>
             {props.results.map(result => <ProductPreview
                   onProductClicked={(product) => props.onProductClicked(product)}
                   key={result.wax.id}
                   product={result.wax}
                   maybeMeanScore={result.meanForCondition}
                   maybeNumberOfReports={result.numberOfReports}
                />
             )}
           </div>
           {props.results.length === 0 && <div>
             <div className="wax-recommendation-results-no-results">
               <Header3 text="Inga resultat hittades :("/>
               <div className="wax-recommendation-no-results-image-container">
                 <img src={notFoundImage} alt="No results found"/>
               </div>
             </div>
           </div>}
         </div>
       </div>
     </div>
  );
};

export default WaxRecommendationResults;
