import jwt from "jsonwebtoken";

export default function AuthenticationService({httpClient, localStorageGateway}) {
  const clientId = "com.vallaloggen.client.web";
  return {
    loginUser,
    logOut,
    saveToken,
    getToken,
    isLoggedIn,
    initLoginByEmail,
    loginByCode
  };

  async function loginUser({googleTokenId}) {
    const response = await httpClient.post(
       "/users/login",
       {clientId},
       {headers: {'Authorization': 'Bearer ' + googleTokenId}}
    );
    return response.data
  }

  function initLoginByEmail(email, clientToken) {
    return httpClient.post("/users/login/email", {clientId, email, clientToken})
       .then(response => response.data)
       .catch(error => Promise.reject(error.response.data.apiError.type));
  }

  function loginByCode(code, clientToken, email) {
    return httpClient.post('/users/login/email/code', {clientId, code, clientToken, email})
       .then(response => response.data.jwt)
       .catch(error => Promise.reject(error.response.data.apiError.type));
  }

  function logOut() {
    localStorageGateway.remove("token");
  }

  function saveToken({token}) {
    token && (typeof token === 'string') && localStorageGateway.set("token", token)
  }

  function getToken() {
    return localStorageGateway.get('token');
  }

  function isLoggedIn() {
    const token = localStorageGateway.get("token");
    if (token) {
      const decodedJwt = jwt.decode(token);
      return decodedJwt.exp * 1000 > Date.now()
    }
    return false
  }
}