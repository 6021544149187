import React, {Component} from 'react';

class Card extends Component {
  render() {
    const classNames = "card-container " + this.props.className;
    return (
       <div className={classNames} onClick={this.props.onClick}>
         {this.props.children}
       </div>
    );
  }
}

export default Card;