import React from 'react';
import ViewContent from "../components/helperComponents/pageSize/ViewContent";
import TopMenu from "../components/TopMenu";
import {NavigationFieldWithHistory} from "../components/navigation/NavigationField";

const Page = (props) => {
  return (
     <div>
       {!props.hideTopMenu && <TopMenu/>}
       <ViewContent>
         {!props.disableNavigationField && <NavigationFieldWithHistory {...props.navigationProps}/>}
         {props.children}
       </ViewContent>
     </div>
  );
};

export default Page;