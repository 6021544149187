import React, {Component} from 'react';
import ReportInput from "../../../../test/app/components/report/ReportInput";
import {connect} from "react-redux";
import {dispatchAction} from "../../../../reduxStore/reduxUtils";

class UpdateReportComponent extends Component {
  updateReport(newReport) {
    dispatchAction(this, this.props.actions.updateReport(this.props.report.id, newReport));
    this.props.onDone();
  }

  render() {
    return (
       <div>
         <ReportInput
            prefillFromReport={this.props.report}
            snowTypes={this.props.condition.snowTypes}
            confirmText={"Uppdatera rapporten"}
            onDone={(newReport) => this.updateReport(newReport)}
         />
       </div>
    );
  }
}


const mapStateToProps = state => ({
  reports: state.reports,
  condition: state.condition
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    actions: {...stateProps.reports.actions},
    ...dispatchProps,
    ...ownProps
  }
};


export default connect(mapStateToProps, null, mergeProps)(UpdateReportComponent);