export default function AdminAddDataService({httpClient, authenticationService}) {

  return {
    createSnowType,
    createWaxSubType,
    createWax,
    fetchWaxSubTypes,
    fetchBrands,
    calculateWaxScores,
    updateWax
  };

  function createSnowType(snowTypeInfo) {
    return httpClient.post(
       `/condition/snow/type`,
       snowTypeInfo,
       {headers: {'Authorization': `Bearer ${authenticationService.getToken()}`}}
    )
       .then(response => response.data)
  }

  function createWaxSubType(waxSubTypeInfo) {
    return httpClient.post(
       `/product/wax/type/sub`,
       waxSubTypeInfo,
       {headers: {'Authorization': `Bearer ${authenticationService.getToken()}`}}
    )
       .then(response => response.data)
  }

  function createWax(waxInfo) {
    return httpClient.post(
       `/product/wax`,
       waxInfo,
       {headers: {'Authorization': `Bearer ${authenticationService.getToken()}`}}
    )
       .then(response => response.data)
  }

  function fetchWaxSubTypes() {
    return httpClient.get(
       `/product/wax/type/sub`,
       {headers: {'Authorization': `Bearer ${authenticationService.getToken()}`}}
    )
       .then(response => response.data)
  }

  function fetchBrands() {
    return httpClient.get(
       `/product/wax/brand`,
       {headers: {'Authorization': `Bearer ${authenticationService.getToken()}`}}
    )
       .then(response => response.data)
  }

  function calculateWaxScores() {
    return httpClient.post(
       `/product/wax/calculate-score`,
       {},
       {headers: {'Authorization': `Bearer ${authenticationService.getToken()}`}}
    )
       .then(response => response.data)
  }

  function updateWax(wax) {
    return httpClient.put(
       `/product/wax`,
       wax,
       {headers: {'Authorization': `Bearer ${authenticationService.getToken()}`}}
    )
       .then(response => response.data)
  }


}