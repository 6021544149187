import format from 'date-fns/format'

export function dateToDateString(jsDate) {
  return (jsDate.getFullYear()) + '-'
     + ('0' + (jsDate.getMonth() + 1)).slice(-2) + '-'
     + ('0' + jsDate.getDate()).slice(-2);
}

export function dateToTimeString(jsDate) {
  return format(jsDate, 'HH:mm');
}

export function dateToJsonExcludingTime(jsDate) {
  return {
    year: jsDate.getFullYear(),
    month: jsDate.getMonth(),
    date: jsDate.getDate()
  };
}

export function timeStringToJson(dateString) {
  const parts = dateString.split(':')
  return {
    hours: parts[0],
    minutes: parts[1],
    seconds: parts[2]
  };
}