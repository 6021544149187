export default {
  positiveHighlight: '#247258',
  primary50: '#2C5DE5',
  primary20: '#91ADFA',
  primary10: '#BFD0FD',
  primary05: '#D7E1FE',
  primary0: '#EEF3FF',
  success50: '#008556',
  success05: '#D6F3E2',
  warning50: '#E86825',
  warning05: '#FFE1BE',
  alert50: '#DE1C22',
  alert05: '#F9D3D4',

  gray100: '#1A1A1A',
  gray80: '#656565',
  gray60: '#A1A1A1',
  gray40: '#C7C7C7',
  gray05: '#E4E4E4',
  gray0: '#F1F1F1',
}