import React, {Component} from 'react';
import {Router, Route, withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import LandingPage from "../pages/LandingPage.js";
import Loginpage from "../pages/userPages/Loginpage.js";
import CreateReportPage from "../pages/CreateReportPage.js";
import ReportDetailsPage from "../pages/ReportDetailsPage";
import UserReportsPage from "../pages/userPages/UserReportsPage";
import AdminPage from "../admin/AdminPage";
import AdminAddData from "../admin/addData/AdminAddData";
import LoggedInRoute from "./LoggedInRoute";
import AdminRoute from "./AdminRoute";
import HelpVallaloggen from "../pages/public/HelpVallaloggen";
import AboutVallaloggen from "../pages/public/AboutVallaloggen";
import PrivacyPolicy from "../pages/public/PrivacyPolicy";
import TermsAndConditions from "../pages/public/TermsAndConditions";
import GoodByePage from "../pages/userPages/GoodByePage";
import HandleUserPopUpComponent from "../components/user/HandleUserPopUpComponent";
import {createBrowserHistory} from "history";
import analytics from "../../analytics/analytics";
import ContactAndFeedback from "../pages/public/ContactAndFeedback";
import AdminCalculateWaxScores from "../admin/calculateScore/AdminCalculateWaxScores";
import UserWaxBoxPage from "../pages/userPages/UserWaxBoxPage";
import RecommendationPage from "../pages/RecommendationPage";
import WaitForCriticalDataToLoad from "../components/WaitForCriticalDataToLoad";
import ProductDetailsPage from "../pages/product/ProductDetailsPage";
import UserPage from "../pages/userPages/UserPage";
import AdminUpdateData from "../admin/updateData/AdminUpdateData";

class AppRouter extends Component {
  componentDidMount() {
    this.initialSetUp()
  }

  history = createBrowserHistory();

  initialSetUp() {
    this.props.dispatch(this.props.fetchServerConfig());
    this.props.dispatch(this.props.fetchUser());
    this.props.dispatch(this.props.fetchSnowTypes())
    this.props.dispatch(this.props.fetchWaxSubTypes())

    this.history.listen(location => {
      analytics.trackPageView(location.pathname)
    });
  }

  render() {
    return (
       <div className="app-container">
         <Router history={this.history}>
           <WaitForCriticalDataToLoad>
             <ScrollToTopComponent>
               <Route path="/" exact component={LandingPage}/>
               <Route path="/login" exact component={Loginpage}/>
               <LoggedInRoute path="/create/report" exact component={CreateReportPage}/>
               <Route path="/reports/:reportId/details" component={ReportDetailsPage}/>
               <Route path="/products/:productId" render={routeProps => <ProductDetailsPage {...routeProps}/>}/>
               <Route path="/recommendation" render={routeProps => <RecommendationPage {...routeProps}/>}/>
               <LoggedInRoute exact path="/user/reports" component={UserReportsPage}/>
               <LoggedInRoute exact path="/user/wax-box" component={UserWaxBoxPage}/>
               <LoggedInRoute exact path="/user" component={UserPage}/>
               <Route path="/good-bye" component={GoodByePage}/>
               {/*Information routes*/}
               <Route path="/help-vallaloggen" component={HelpVallaloggen}/>
               <Route path="/about" component={AboutVallaloggen}/>
               <Route path="/privacy-policy" component={PrivacyPolicy}/>
               <Route path="/terms-and-conditions" component={TermsAndConditions}/>
               <Route path="/contact-and-feedback" component={ContactAndFeedback}/>
               {/*Admin routes*/}
               <AdminRoute path="/admin" exact component={AdminPage}/>
               <AdminRoute path="/admin/add" exact component={AdminAddData}/>
               <AdminRoute path="/admin/update" exact component={AdminUpdateData}/>
               <AdminRoute path="/admin/calculate-wax-scores" exact component={AdminCalculateWaxScores}/>
             </ScrollToTopComponent>
           </WaitForCriticalDataToLoad>
         </Router>
         <HandleUserPopUpComponent/>
       </div>
    );
  }
}

const mapStateToProps = state => ({
  clientInfo: state.clientInfo,
  user: state.user,
  authentication: state.authentication,
  condition: state.condition,
  product: state.product
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...stateProps.clientInfo.actions,
    ...stateProps.user.actions,
    ...stateProps.condition.actions,
    ...stateProps.product.actions,
    ...dispatchProps,
    ...ownProps
  }
};

export default connect(mapStateToProps, null, mergeProps)(AppRouter);

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

const ScrollToTopComponent = withRouter(ScrollToTop);