import React, {Component} from 'react';
import RecommendationFilter from "../filter/RecommendationFilter";
import PrimaryButton from "../../components/helperComponents/buttons/PrimaryButton";
import Header3 from "../../components/helperComponents/text/Header3";
import colors from "../../../style/colors";

class FilterStep extends Component {
  state = {
    filter: null
  }

  updateFilter(filter) {
    this.setState({filter})
    this.props.onFilterChanged(filter)
  }

  render() {
    const params = new URLSearchParams(this.props.location.search);
    const filter = {
      brand: params.get('brand'),
      waxType: params.get('waxType'),
      myWaxes: params.get('myWaxes') === 'true'
    }
    return (
       <div>
         <div className="recommendation-page-filter-text-container">
           <Header3 text="Filter" icon="filter" iconColor={colors.warning50}/>
           <p>Letar du efter särskilda vallor? Här kan du välja att enbart få förslag på en specifik vallatyp
             eller ett märke.</p>
         </div>
         <div>
           <RecommendationFilter
              initialFilter={filter}
              onFilterChange={filter => this.updateFilter(filter)}
              onAddMoreWaxes={() => this.props.onAddMoreWaxes()}/>
           <PrimaryButton onClick={() => this.props.onProceed(this.state.filter)} label="Hitta förslag"/>
         </div>
       </div>
    );
  }
}

export default FilterStep;