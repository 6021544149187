import events from '../../actionEvents.js';

export default function ReportsModule({reportsService}) {

  const baseState = {
    actions: {
      // todo delete setCurrentReport when report details is fixed
      setCurrentReport,
      createReport,
      resetReportCreatedState,
      searchReports,
      fetchReport,
      upVoteReport,
      downVoteReport,
      resetFailedVote,
      fetchUserSpecificsForReport,       // todo delete fetchUserSpecificsForReport when report details is fixed
      fetchReportDetails,
      fetchAllUsersReports,
      deleteReport,
      updateReport
    },
    searchResults: [],
    userReports: [],
    currentReport: null,
    currentReportDetails: null,
    reportWasNotFound: false,
    voteFailed: false,
    reportCreated: null
  };

  return {
    reducer,
    actions: baseState.actions
  };

  function setCurrentReport(report) {
    return (dispatch) => {
      dispatch(events.setCurrentReport(report))
    }
  }

  function fetchReport(id) {
    return async (dispatch) => {
      const report = await reportsService.fetchReport(id, () => dispatch(events.reportNotFound(id)));
      dispatch(events.setCurrentReport(report));
    }
  }

  function fetchAllUsersReports() {
    return async (dispatch) => {
      await reportsService.fetchAllReportsForUser()
         .then(reports => reports.map(fixReportDate))
         .then(reports => dispatch(events.setUserReports(reports)));
    }
  }

  function createReport(report) {
    return async (dispatch) => {

      const createdReport = await reportsService.createReport(report);
      dispatch(events.setReportCreated(createdReport));
      dispatch(events.setUserReports([]));
      dispatch(events.setCurrentReport(null));
    }
  }

  // the field `reportCreated` is used in CreateReportComponent to know when to redirect.
  // A bit messy. Here we need to set it back to not redirect again if user want to create new report
  function resetReportCreatedState() {
    return async (dispatch) => {
      dispatch(events.setReportCreated(null));
    }
  }

  function searchReports(queryObject) {
    return async (dispatch) => {
      const responseSearchResult = await reportsService.fetchSearchResults(queryObject);
      const searchResults = responseSearchResult.map(ratedReport => ({
        sortOrder: ratedReport.sortOrder,
        report: fixReportDate(ratedReport.report)
      }));
      dispatch(events.setSearchResults(searchResults))
    }
  }

  function upVoteReport(id, currentVoteType) {
    return async (dispatch, getState) => {
      const newVoteResult = await reportsService.upVoteReport(id, (error) => {
        dispatch(events.setVoteFailed())
      });
      if (newVoteResult) {
        const currentReport = {...getState().reports.currentReport, ...{votes: newVoteResult}};
        dispatch(events.setCurrentReport(currentReport));
        const voteType = currentVoteType === 'UpVote' ? 'NoVote' : 'UpVote';
        dispatch(events.setUserVoteType(voteType))
      }
    }
  }

  function downVoteReport(id, currentVoteType) {
    return async (dispatch, getState) => {
      const newVoteResult = await reportsService.downVoteReport(id, (error) => {
        dispatch(events.setVoteFailed())
      });
      if (newVoteResult) {
        const currentReport = {...getState().reports.currentReport, ...{votes: newVoteResult}};
        dispatch(events.setCurrentReport(currentReport));
        const voteType = currentVoteType === 'DownVote' ? 'NoVote' : 'DownVote';
        dispatch(events.setUserVoteType(voteType))
      }
    }
  }

  function resetFailedVote() {
    return async (dispatch) => {
      dispatch(events.resetVoteFailed())
    }
  }

  function fetchUserSpecificsForReport(reportId) {
    return async (dispatch) => {
      await reportsService.fetchUserSpecificsForReport(reportId)
         .then(userSpecifics => dispatch(events.setUserSpecificsForReport(userSpecifics)))
         .catch(error => dispatch(events.setUserSpecificsForReport({currentVoteType: 'NoVote'})))
    }
  }

  function fetchReportDetails(reportId) {
    return async (dispatch) => {
      const details = await reportsService.fetchReportDetails(reportId)
      dispatch(events.setCurrentReportDetails(fixReportDate(details)))
    }
  }

  function deleteReport(reportId) {
    return async (dispatch) => {
      await reportsService.deleteReport(reportId);
      dispatch(events.setUserReports([]));
    }
  }

  function updateReport(reportId, reportInfo) {
    return async (dispatch, getState) => {
      await reportsService.updateReport(reportId, reportInfo);
      dispatch(getState().reports.actions.fetchReportDetails(reportId));
    }
  }


  function fixReportDate(report) {
    return {
      ...report,
      createdAt: new Date(report.createdAt.split('[')[0]),
      skiingDate: report.skiingDate ? new Date(report.skiingDate.split('[')[0]) : '', // date required on new reports
    };
  }

  function reducer(state = baseState, action) {
    switch (action.type) {
      case 'SET_SEARCH_RESULTS':
        return {
          ...state,
          searchResults: action.searchResults
        };
      case 'SET_USER_REPORTS':
        return {
          ...state,
          userReports: action.reports
        };
      case 'SET_CURRENT_REPORT':
        return {
          ...state,
          currentReport: action.report
        };
      case 'SET_REPORT_CREATED':
        return {
          ...state,
          reportCreated: action.report
        };
      case 'REPORT_NOT_FOUND':
        return {
          ...state,
          reportWasNotFound: true
        };
      case 'VOTE_FAILED':
        return {
          ...state,
          voteFailed: true
        };
      case 'RESET_VOTE_FAILED':
        return {
          ...state,
          voteFailed: false
        };
      case 'SET_USER_SPECIFICS':
        return {
          ...state,
          userSpecifics: action.userSpecifics
        };
      case 'SET_USER_VOTE_TYPE':
        return {
          ...state,
          userSpecifics: {...state.userSpecifics, ...{currentVoteType: action.voteType}}
        };
      case 'SET_CURRENT_REPORT_DETAILS':
        return {
          ...state,
          currentReportDetails: action.details
        }
      default:
        return state;
    }
  }
}