import React, {Component} from 'react';
import RecommendationSearchSection from "./RecommendationSearchSection";
import {connect} from "react-redux";
import {dispatchAction} from "../../reduxStore/reduxUtils";
import {truthyOrZero} from "../../utils/intUtils";
import WaxRecommendationResults from "./WaxRecommendationResults";
import {Tabs, Tab} from "../components/helperComponents/tabs/Tabs";
import ReportsSearchResult from "./ReportsSearchResult";
import {createUrlParametersFromObject} from "../../utils/httpUtils";
import SkeletonLoader from "../components/helperComponents/loading/SkeletonLoader";

class RecommendationComponent extends Component {

  state = {
    currentCondition: null,
    currentFilter: null,
    activeTabName: "Vallor"
  }

  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search);

    const temperature = parseInt(searchParams.get('temperature'));
    const maybeTemperature = truthyOrZero(temperature) ? temperature : ''
    const maybeSnowTypeId = searchParams.get('snowTypeId');
    const currentCondition = {temperature: maybeTemperature, snowTypeId: maybeSnowTypeId}

    const maybeBrandFilter = searchParams.get('brand');
    const maybeWaxTypeFilter = searchParams.get('waxType');
    const maybeMyWaxes = searchParams.get('myWaxes') === "true";
    const currentFilter = {brand: maybeBrandFilter, waxType: maybeWaxTypeFilter, myWaxes: maybeMyWaxes}

    const maybeActiveTabName = new URLSearchParams(this.props.location.search).get('tab')
    const tabName = ["Vallor", "Rapporter"].includes(maybeActiveTabName) ? maybeActiveTabName : "Vallor"

    this.setState({currentCondition, currentFilter, activeTabName: tabName});

    this.searchForWaxes(currentCondition, currentFilter)
    this.searchForReports(currentCondition, currentFilter)
  }

  updateSearchAfterChangedCondition(changedCondition) {
    const newSnowTypeId = changedCondition.snowType ? changedCondition.snowType.id : null
    const newCondition = {temperature: changedCondition.temperature, snowTypeId: newSnowTypeId}
    const filter = this.state.currentFilter
    this.setState({currentCondition: newCondition});
    this.searchForWaxes(newCondition, this.state.currentFilter)
    this.searchForReports(newCondition)
    this.props.history.push({
      search: createUrlParametersFromObject({
        ...newCondition,
        brand: filter && filter.brand,
        waxType: filter && filter.waxType,
        myWaxes: filter && (filter.myWaxes || null),
        tab: this.state.activeTabName
      })
    })

  }

  updateSearchAfterChangedFilter(changedFilter) {
    this.setState({currentFilter: changedFilter})
    this.searchForWaxes(this.state.currentCondition, changedFilter)
    this.searchForReports(this.state.currentCondition, changedFilter)
    this.props.history.push({
      search: createUrlParametersFromObject({
        ...this.state.currentCondition,
        brand: changedFilter && changedFilter.brand,
        waxType: changedFilter && changedFilter.waxType,
        myWaxes: changedFilter && (changedFilter.myWaxes || null)
      })
    })

  }

  searchForWaxes(condition, filter = null) {
    dispatchAction(this, this.props.actions.searchWaxProducts({
      airTemperature: condition.temperature,
      snowTypeId: condition.snowTypeId ? condition.snowTypeId : null,
      waxBrand: filter && filter.brand,
      waxType: filter && filter.waxType,
      myWaxes: filter && (filter.myWaxes || null)
    }));
  }

  searchForReports(condition, filter = null) {
    dispatchAction(this, this.props.actions.searchReports({
      airTemperature: condition.temperature,
      snowTypeId: condition.snowTypeId,
      waxBrand: filter && filter.brand,
      waxType: filter && filter.waxType,
      myWaxes: filter && (filter.myWaxes || null)
    }));
  }

  render() {
    const allSnowTypes = this.props.condition.snowTypes;
    return (
       <div className="recommendation-component-container">
         <div className="recommendation-component-search-container">
           {this.state.currentCondition &&
           <RecommendationSearchSection
              onConditionChanged={(condition) => this.updateSearchAfterChangedCondition(condition)}
              onFilterChanged={(newFilter) => this.updateSearchAfterChangedFilter(newFilter)}
              condition={this.state.currentCondition}
              filter={this.state.currentFilter}
              snowTypes={allSnowTypes}/>}
         </div>
         <div className="recommendation-component-results-container">
           <div>
             <Tabs activeChildTitle={this.state.activeTabName}
                   onChange={({title, index}) => this.setActiveTab(title, index)}>
               <Tab title="Vallor">
                 {!this.props.product.isFetchingRecommendationResults
                    ? <WaxRecommendationResults
                       results={this.props.product.recommendationResults}
                       onProductClicked={(product) => this.goToProduct(product)}/>
                    : <div><SkeletonLoader instructions={Array.from(Array(10).keys()).map(() => "header")}/></div>
                 }
               </Tab>
               <Tab title="Rapporter">
                 <ReportsSearchResult
                    results={this.props.reports.searchResults}
                    onReportClicked={(r) => this.goToReport(r)}/>
               </Tab>
             </Tabs>
           </div>
         </div>
       </div>
    );
  }

  goToProduct(product) {
    this.props.history.push(`/products/${product.id}`, {from: "SEARCH"});
  }

  goToReport(report) {
    this.props.history.push(`/reports/${report.id}/details`, {from: "SEARCH"});
  }

  setActiveTab(title) {
    const filter = this.state.currentFilter
    this.setState({activeTabName: title})
    this.props.history.push({
      search: createUrlParametersFromObject({
        ...this.state.currentCondition,
        brand: filter && filter.brand,
        waxType: filter && filter.waxType,
        myWaxes: filter && (filter.myWaxes || null),
        tab: title
      })
    })
  }
}

const mapStateToProps = state => ({
  product: state.product,
  condition: state.condition,
  reports: state.reports
});
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    actions: {...stateProps.product.actions, ...stateProps.reports.actions},
    ...dispatchProps,
    ...ownProps
  }
};


export default connect(mapStateToProps, null, mergeProps)(RecommendationComponent);