export function createUrlParametersFromObject(object) {
  const query = {};
  for (let [key, value] of Object.entries(object)) {
    if ((value !== '' && value !== null) && value !== []) {
      if (Array.isArray(value)) {
        if (value.length) {
          value = value.join(',');
          query[key] = value
        }
      } else {
        query[key] = value
      }
    }
  }
  return Object
     .entries(query)
     .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
     .join('&');
}