import React, {Component} from 'react';
import './_createReportComponent.scss';
import {connect} from "react-redux";
import {dispatchAction} from "../../../../reduxStore/reduxUtils";
import {scrollTopHard} from "../../../../utils/scrollUtils";
import ReportInput from "../../../../test/app/components/report/ReportInput";

class CreateReportComponent extends Component {

  componentDidUpdate() {
    // When report is created on the server we set this value.
    // Resetting it so we don't get redirected at once next time visiting the component.
    if (this.props.reports.reportCreated) {
      dispatchAction(this, this.props.actions.resetReportCreatedState())
      scrollTopHard()
      this.props.onDone();
    }
  }

  createReport(report) {
    dispatchAction(this, this.props.actions.createReport(report))
  }

  render() {
    return (
       <ReportInput
          snowTypes={this.props.condition.snowTypes}
          confirmText={"Skapa rapporten"}
          onDone={(report) => this.createReport(report)}
       />
    );
  }
}

const mapStateToProps = state => ({
  reports: state.reports,
  condition: state.condition
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    actions: {...stateProps.reports.actions},
    ...dispatchProps,
    ...ownProps
  }
};


export default connect(mapStateToProps, null, mergeProps)(CreateReportComponent);
