import React, {Component} from 'react';
import {SpecificationField} from "../../helperComponents/information/SpecificationField";
import {groupBy} from "../../../../utils/groupBy";
import ProductPreview from "../../product/productPreview/ProductPreview";
import Header3 from "../../helperComponents/text/Header3";
import './_reportDetails.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import colors from "../../../../style/colors";
import {dateToDateString} from "../../../../utils/dateTime";
import {gradeToHuman} from "../../../../utils/gradeUtils";
import Header1 from "../../helperComponents/text/Header1";
import TextButton from "../../helperComponents/buttons/TextButton";

class ReportDetailsComponent extends Component {

  goToProductDetails(product) {
    this.props.history.push(`/products/${product.id}/details`);
  }

  colorByRating(rating) {
    return {
      bad: colors.warning50,
      good: colors.gray100,
      very_good: colors.primary50
    }[rating]
  }

  requestUpdateMode() {
    this.props.onRequestUpdateMode();
  }

  render() {
    const {reportDetails} = this.props;
    const snowType = this.props.snowTypes.find(s => s.id === reportDetails.snowType).snowType;
    const waxGroups = groupBy(reportDetails.products, 'waxType');
    const hasGliders = !!waxGroups.glider
    const hasKickwax = !!waxGroups.kickwax
    const hasRiller = !!waxGroups.riller
    return (
       <div className="report-details-container">
         <div>
           <div className="report-details-header-section">
             <div className="report-details-title">
               <Header1 title={reportDetails.title}/>
             </div>
             <div className="report-details-meta-data">
               <div className="report-details-owner">
                 <FontAwesomeIcon icon={["far", "user"]}/> <i>{reportDetails.userName}</i>
               </div>
               <div className="report-details-skiing-date">
                 <FontAwesomeIcon
                    icon={["far", "calendar"]}/> <i>{dateToDateString(new Date(reportDetails.skiingDate))}</i>
               </div>
             </div>
           </div>
           <div className="report-details-condition">
             <SpecificationField
                leftIcon="thermometer-quarter"
                rightIcon="snowflake"
                iconColor={colors.primary50}
                left={reportDetails.temperature}
                right={snowType}/>
           </div>
           <div className="report-details-ratings">
             <SpecificationField
                leftIcon={reportDetails.gripRating && ["far", "star"]}
                rightIcon={reportDetails.glideRating && ["far", "star"]}
                leftIconColor={this.colorByRating(reportDetails.gripRating)}
                rightIconColor={this.colorByRating(reportDetails.glideRating)}
                left={reportDetails.gripRating ? `${gradeToHuman(reportDetails.gripRating)} fäste` : ''}
                right={reportDetails.glideRating ? `${gradeToHuman(reportDetails.glideRating)} glid` : ''}/>
           </div>
           <div className="report-details-description">
             <i>{reportDetails.description}</i>
           </div>
           <div>
             {hasKickwax && this.renderWaxGroup(waxGroups.kickwax, 'Fästvallor', 'grip')}
             {hasGliders && this.renderWaxGroup(waxGroups.glider, 'Glidvallor', 'glide')}
             {hasRiller && this.renderWaxGroup(waxGroups.riller, 'Rillers', 'riller')}
           </div>
           {this.props.maybeUserId === reportDetails.createdBy &&
           <div className="report-details-update-button-container">
             <TextButton
                label="Updattera rapporten"
                onClick={() => this.requestUpdateMode()}
                className={"report-details-option-update"}/>
           </div>}
         </div>
       </div>
    );
  }

  renderWaxGroup(products, title, classPart) {
    return (
       <div className={`report-details-${classPart}-products`}>
         <Header3 text={title}/>
         {products.map(product => <ProductPreview
            key={product.id}
            product={product}
            onProductClicked={() => this.goToProductDetails(product)}/>)}
       </div>
    )
  }
}

export default ReportDetailsComponent;