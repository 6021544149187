import React, {Component} from 'react';
import {connect} from "react-redux";
import Page from "./Page";
import CreateReportComponent from "../components/report/create/CreateReportComponent";
import InformationField from "../components/helperComponents/text/PageInformation";
import VallaloggenAnimation from "../components/helperComponents/loading/VallaloggenAnimation";

class CreateReportPage extends Component {

  state = {
    showLoading: false,
  };

  goToUserPagesAfterTimeout() {
    this.setState({showLoading: true}, () => {
      setTimeout(() => {
        this.setState({showLoading: false})
        this.props.history.push('/user')
      }, 2500)
    })
  }

  render() {
    return (
       <div>
         {
           !this.state.showLoading
              ? <Page disableNavigationField={true}>
                <div className="create-report-page">
                  <InformationField>
                    <p>Om du skapar en rapport efter skidåkningen
                      kommer du lättare ihåg din vallning. Du gör
                      också Vallaloggen smartare!</p>
                  </InformationField>
                  <CreateReportComponent onDone={() => this.goToUserPagesAfterTimeout()}/>
                </div>
              </Page>
              : <VallaloggenAnimation endMessage="Din rapport har skapats!"/>
         }
       </div>
    )
       ;
  }
}

const mapStateToProps = state => ({
  reports: state.reports
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    actions: {...stateProps.reports.actions},
    ...dispatchProps,
    ...ownProps
  }
};


export default connect(mapStateToProps, null, mergeProps)(CreateReportPage)