import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './_navigation.scss';
import {withRouter} from "react-router-dom";


class NavigationField extends Component {

  navigateBack() {
    if (this.props.backTo) this.props.history.push(this.props.backTo)
    else this.props.history.goBack();
  }

  render() {
    const label = this.props.label || "Tillbaka";
    return (
       <div className="navigation-field-container" onClick={() => this.navigateBack()}>
         <FontAwesomeIcon icon="caret-left" size="lg"/>
         <div className="navigation-field-text">{label}</div>
       </div>
    );
  }
}

export const NakedNavigationField = NavigationField;

export const NavigationFieldWithHistory = withRouter(NavigationField)