import React, {Component} from 'react';
import {connect} from "react-redux";
import {dispatchAction} from "../../../../reduxStore/reduxUtils";
import ProductDetailsComponent from "./ProductDetailsComponent";
import SkeletonLoader from "../../helperComponents/loading/SkeletonLoader";

class ProductDetailsProviderComponent extends Component {

  componentDidMount() {
    const productId = this.props.match.params.productId;
    dispatchAction(this, this.props.actions.fetchProductDetails(productId))
  }

  render() {
    const isLoading = this.props.product.isFetchingWaxProducts || !this.props.product.currentProductDetails;
    return (
       <div>
         {isLoading
            ? <SkeletonLoader instructions={['header', 'line', 'line', 'pause32', 'block']}/>
            : <ProductDetailsComponent
               productDetails={this.props.product.currentProductDetails}
               snowTypes={this.props.condition.snowTypes}
               waxSubTypes={this.props.product.waxSubTypes}
               currentUserId={this.props.user.user ? this.props.user.user.id : null}
               history={this.props.history}
            />
         }
       </div>
    );
  }
}


const mapStateToProps = state => ({
  product: state.product,
  condition: state.condition,
  user: state.user
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    actions: {...stateProps.product.actions},
    ...dispatchProps,
    ...ownProps
  }
};


export default connect(mapStateToProps, null, mergeProps)(ProductDetailsProviderComponent)