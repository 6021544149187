import React, {Component} from 'react';
import OutlinedLinkButton from "../../helperComponents/buttons/OutlinedLinkButton";
import './_myAccount.scss';
import SettingsComponent from "../settings/SettingsComponent";

class MyAccountComponent extends Component {
  render() {
    return (
       <div>
         <div className="user-account-button-set">
           <strong>Min vallning</strong>
           <div className="user-account-button-container">
             <OutlinedLinkButton to="/user/wax-box" text="Mina vallor" icon="briefcase"/>
           </div>
           <div className="user-account-button-container">
             <OutlinedLinkButton to="/user/reports" text="Mina rapporter" icon="book-open"/>
           </div>
         </div>
         <div className="user-account-button-set">
           <strong>Övrigt</strong>
           <div className="user-account-button-container">
             <SettingsComponent history={this.props.history}/>
           </div>
         </div>
       </div>
    );
  }
}

export default MyAccountComponent;