import React, {Component} from 'react';
import ConditionsSelect from "../components/conditions/ConditionsSelect";
import './recommendation-search-section.scss'
import {truthyOrZero} from "../../utils/intUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import colors from '../../style/colors'
import Header3 from "../components/helperComponents/text/Header3";
import Modal from "../components/helperComponents/Modal";
import PrimaryButton from "../components/helperComponents/buttons/PrimaryButton";
import {systemWaxTypeToHuman} from "../../utils/waxUtils";
import RecommendationFilter from "./filter/RecommendationFilter";
import EditWaxBoxComponent from "./filter/EditWaxBoxComponent";

const VIEW_MODE = 'view'
const EDIT_CONDITION_MODE = 'edit_condition'
const EDIT_FILTER_MODE = 'edit_filter'
const ADD_WAXES_MODE = 'add_waxes'


// TODO
/**
 * Rename this to ConditionView or something. Or extract the conditions parts of it.
 *
 */

class RecommendationSearchSection extends Component {
  state = {
    mode: VIEW_MODE,
    changedCondition: null,
    changedFilter: null
  }

  componentDidMount() {
    if (this.props.filter) this.setState({changedFilter: this.props.filter});
  }

  enterEditConditionMode() {
    this.setState({mode: EDIT_CONDITION_MODE});
  }

  enterEditFilterMode() {
    this.setState({mode: EDIT_FILTER_MODE});
  }

  enterViewMode() {
    this.setState({mode: VIEW_MODE});
  }

  changeCondition(condition) {
    this.setState({changedCondition: condition});
  }

  changeFilter(newFilter) {
    this.setState({changedFilter: newFilter});
  }

  provideChangedCondition() {
    if (this.state.changedCondition) {
      this.props.onConditionChanged(this.state.changedCondition);
    }
    this.enterViewMode()
  }

  provideChangedFilter() {
    if (this.state.changedFilter) {
      this.props.onFilterChanged(this.state.changedFilter);
    }
    this.enterViewMode();
  }

  showEditWaxBox() {
    this.setState({mode: ADD_WAXES_MODE})
  }

  hideEditWaxBox() {
    this.setState({mode: EDIT_FILTER_MODE})
  }

  render() {
    return (
       <div className="recommendation-search-section">
         <div className="recommendation-search-section-container">
           <div className="recommendation-search-section-condition-header">
             <Header3 icon="snowflake" iconColor={colors.primary50} text="Före"/>
           </div>
           <div onClick={() => this.enterEditConditionMode()}
                className="recommendation-search-section-current-condition recommendation-search-section-block">
             {this.renderCondition()}
             <FontAwesomeIcon icon="edit"/>
           </div>
           <div>
             <div>
               <Header3 icon="filter" iconColor={colors.warning50} text="Filter"/>
             </div>
             <div onClick={() => this.enterEditFilterMode()}
                  className="recommendation-search-section-current-filter recommendation-search-section-block">
               {this.renderFilter()}
               <FontAwesomeIcon icon="edit"/>
             </div>
           </div>
         </div>
         {this.state.mode !== VIEW_MODE && <div>
           <Modal onClickOutSide={() => this.enterViewMode()}>
             <div>
               {this.state.mode === EDIT_CONDITION_MODE && <div>
                 <ConditionsSelect
                    onConditionChange={(condition) => this.changeCondition(condition)}
                    initialSnowTypeId={this.props.condition.snowTypeId}
                    initialTemperature={this.props.condition.temperature}
                    allowDeselectSnowType={true}
                 />
                 <PrimaryButton label={"Uppdatera"} onClick={() => this.provideChangedCondition()}/>
               </div>}
               {this.state.mode === EDIT_FILTER_MODE && <div>
                 <RecommendationFilter
                    initialFilter={this.state.changedFilter}
                    onFilterChange={(newFilter) => this.changeFilter(newFilter)}
                    onAddMoreWaxes={() => this.showEditWaxBox()}
                 />
                 <PrimaryButton label={"Uppdatera"} onClick={() => this.provideChangedFilter()}/>
               </div>}
               {this.state.mode === ADD_WAXES_MODE && <div>
                 <EditWaxBoxComponent onDone={() => this.hideEditWaxBox()}/>
               </div>}
             </div>
           </Modal>
         </div>}
       </div>
    );
  }

  renderCondition() {
    const temperature = truthyOrZero(this.props.condition.temperature) ? this.props.condition.temperature + '°C' : ''
    const snowType = this.props.condition.snowTypeId
       ? this.props.snowTypes.find(s => s.id === this.props.condition.snowTypeId).snowType
       : 'alla snötyper'

    const maybeAnd = temperature && snowType ? ' och ' : ''

    return <span>{temperature}{maybeAnd}{snowType}</span>
  }

  renderFilter() {
    const hasActiveFilter = !!this.props.filter && (this.props.filter.brand || this.props.filter.waxType || this.props.filter.myWaxes);
    if (hasActiveFilter) {
      const brand = this.props.filter.brand ? `${this.props.filter.brand}` : ''
      const waxType = this.props.filter.waxType ? systemWaxTypeToHuman(this.props.filter.waxType) : ''
      const myWaxes = this.props.filter.myWaxes ? 'Enbart mina vallor' : ''
      const maybeSeparator = brand && waxType ? ', ' : '';
      const maybeSecondSeperator = (brand || waxType) && myWaxes ? ', ' : ''
      return <span>{brand}{maybeSeparator}{waxType}{maybeSecondSeperator}{myWaxes}</span>
    } else {
      return <span>Inget filter</span>
    }
  }
}

export default RecommendationSearchSection;