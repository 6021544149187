export default function ClientInfoService({httpClient}) {
  return {
    fetchClientInfo
  };

  async function fetchClientInfo() {
    const response =  await httpClient.get("/client-info");
    return response.data;
  }
}
