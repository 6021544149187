import React, {Component} from 'react';
import Header1 from "../../components/helperComponents/text/Header1";

class GoodByePage extends Component {
  render() {
    return (
       <div style={{
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
         marginTop: "64px"
       }}>
         <div style={{maxWidth: "75vw"}}>
           <div>
             <Header1 title="Tråkigt att se dig gå!"/>
             Det var tråkigt att du valt att avsluta ditt konto. Vi på vallaloggen skulle upskatta om du kan ta ett
             par
             minuter och sammanfatta varför du valt att avsluta kontot. Skicka gärna ett mail till <a
              href="mailto:info@vallaloggen.se">info@vallaloggen.se</a> med en beskrivning på vad som fått dig att
             avsluta kontot. Tack för den här tiden, och lycka till i skidspåret. :)
           </div>
         </div>
       </div>
    );
  }
}

export default GoodByePage;