import React, {Component} from 'react';
import EditWaxBoxComponent from "../filter/EditWaxBoxComponent";

class EditWaxBoxStep extends Component {

  render() {
    return (
       <div>
         <EditWaxBoxComponent onDone={() => this.props.onDone()}/>
       </div>
    );
  }
}

export default EditWaxBoxStep;