import React, {Component} from 'react';
import {connect} from "react-redux";
import ProductSearchSelect from "../ProductSearchSelect";
import {groupBy} from "../../../../utils/groupBy";
import analytics from "../../../../analytics/analytics";
import ProductPreview from "../productPreview/ProductPreview";
import Header3 from "../../helperComponents/text/Header3";


class UserWaxBoxComponent extends Component {

  componentDidMount() {
    this.props.dispatch(this.props.actions.fetchUserWaxBox());
  }

  addWaxToWaxBox(wax) {
    this.props.dispatch(this.props.actions.addWaxToWaxBox(wax));
    analytics.addedWaxToWaxBox(`${wax.brand} ${wax.waxName}`)
  }

  goToProductDetails(item) {
    !this.props.disableProductClick && this.props.history.push(`/products/${item.id}`)
  }

  render() {
    const user = this.props.user.user;
    if (!user) {
      return <div/>
    }
    const waxBoxItems = this.props.product.userWaxBoxItems;
    const itemsGroupedByWaxSubType = groupBy(waxBoxItems.sort((a, b) => a.waxSubType.localeCompare(b.waxSubType)), 'waxSubType');
    return (
       <div>
         <div className="user-wax-box-add-new-wax">
           <ProductSearchSelect
              onSelect={(wax) => this.addWaxToWaxBox(wax)}
              ignoreIds={this.props.product.userWaxBoxItems.map(i => i.id)}
              triggerComponentType={"PRIMARY_BUTTON"}
           />
         </div>
         <div>
           <div>
             {!!waxBoxItems.length
             && Object.entries(itemsGroupedByWaxSubType).map(([groupName, items]) => {
               return <div key={groupName} className="user-wax-box-group">
                 <Header3 text={groupName} className="user-wax-box-group-title"/>
                 <div className="user-wax-box-group-items">
                   {items.map(item => <ProductPreview
                      product={item}
                      key={item.id}
                      onProductClicked={() => this.goToProductDetails(item)}/>)}
                 </div>
               </div>
             })
             }
           </div>
         </div>
       </div>
    );
  }
}


const mapStateToProps = state => ({
  product: state.product,
  reports: state.reports,
  user: state.user,
  condition: state.condition
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    actions: {...stateProps.product.actions, ...stateProps.condition.actions},
    ...dispatchProps,
    ...ownProps
  }
};


export default connect(mapStateToProps, null, mergeProps)(UserWaxBoxComponent);


// function UserWaxBoxItem(props) {
//
//   function getDisplayNameForWax(wax) {
//     return `${wax.brand} ${wax.waxName} (${wax.waxSubType})`
//   }
//
//   function getDisplayNameForCondition(condition) {
//     return `${condition.fromTemperature} / ${condition.toTemperature} ${condition.snowType}`
//   }
//
//   const item = props.item;
//   const itemHasRelatedReports = item.relatedReports && item.relatedReports.length;
//   const userRelatedReports = itemHasRelatedReports ? item.relatedReports.filter(r => r.createdBy === props.userId) : [];
//   return (
//      <div className="user-wax-box-item">
//        <Card className="user-wax-box-item-card">
//          <div className="user-wax-box-wax-name user-wax-box-wax-info-row">{getDisplayNameForWax(item)}</div>
//          <div className="user-wax-box-item-info">
//            <div>{item.conditions.map((c, i) => <div
//               className="user-wax-box-wax-condition user-wax-box-wax-info-row"
//               key={i}>{getDisplayNameForCondition(c)}</div>)}
//            </div>
//            {itemHasRelatedReports ?
//               <div
//                  onClick={() => props.onClickRelatedReports(item.relatedReports)}
//                  className="user-wax-box-wax-related-reports user-wax-box-wax-info-row primary-link">
//                 {`Denna produkt används i ${item.relatedReports.length} rapporter`}
//               </div> : null}
//            {userRelatedReports.length ?
//               <div
//                  onClick={() => props.onClickRelatedReports(userRelatedReports)}
//                  className="user-wax-box-wax-related-reports-by-user user-wax-box-wax-info-row primary-link">
//                 {`Du har använt denna produkt i ${userRelatedReports.length} rapporter`}
//               </div> : null}
//          </div>
//        </Card>
//      </div>
//   );
// }
//
