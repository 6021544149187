import React, {Component} from 'react';
import {Tab, Tabs} from "grommet";
import AddWaxComponent from "./AddWaxComponent";
import AddWaxSubTypeComponent from "./AddWaxSubTypeComponent";
import AddSnowTypeComponent from "./AddSnowTypeComponent";

class AdminAddData extends Component {
  render() {
    return (
       <div>
         <div className="admin-tabs">
           <Tabs>
             <Tab title="Valla"><AddWaxComponent/></Tab>
             <Tab title="Valla - subtyp"><AddWaxSubTypeComponent/></Tab>
             <Tab title="Snötyp"><AddSnowTypeComponent/></Tab>
           </Tabs>
         </div>
       </div>
    );
  }
}

export default AdminAddData;