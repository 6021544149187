export default function SettingsRepository({localStorageGateway}) {

  const settings = {
    useAdvancedSnowTypes: localStorageGateway.get('useAdvancedSnowTypes') === 'true'
  };
  return {
    getSettings,
    updateSetting
  };

  function getSettings() {
    return settings;
  }

  function updateSetting(settingKey, settingValue) {
    settings.useAdvancedSnowTypes = settingValue;
    localStorageGateway.set(settingKey, settingValue)
  }

}