import {createUrlParametersFromObject} from "../../../utils/httpUtils";
import {removeBlanks} from "../../../utils/objectUtils";

export default function ReportsService({httpClient, authenticationService}) {
  return {
    createReport,
    fetchSearchResults,
    fetchReport,
    fetchAllReportsForUser,
    upVoteReport,
    downVoteReport,
    fetchUserSpecificsForReport,
    deleteReport,
    updateReport,
    fetchReportDetails
  };

  async function createReport(report) {
    const response = await httpClient.post(
       '/reports',
       removeBlanks(report),
       {headers: {'Authorization': `Bearer ${authenticationService.getToken()}`}}
    );
    return response.data
  }

  async function fetchSearchResults(queryObject) {
    const query = createUrlParametersFromObject(queryObject)
    const response = await httpClient.get(`/reports/search?${query}`);
    return response.data;
  }

  function fetchReport(id, onError) {
    return httpClient.get(`/reports/${id}`)
       .then(response => response.data)
       .catch(error => onError(error.response.data.apiError.type));
  }

  function fetchAllReportsForUser() {
    return httpClient.get(
       '/reports/user',
       {headers: {'Authorization': `Bearer ${authenticationService.getToken()}`}}
    ).then(response => response.data)
  }

  function upVoteReport(id, onError) {
    return httpClient.post(
       `/reports/${id}/vote/up`,
       {},
       {headers: {'Authorization': `Bearer ${authenticationService.getToken()}`}}
    )
       .then(response => response.data)
       .catch(error => onError());

  }

  function downVoteReport(id, onError) {
    return httpClient.post(
       `/reports/${id}/vote/down`,
       {},
       {headers: {'Authorization': `Bearer ${authenticationService.getToken()}`}}
    )
       .then(response => response.data)
       .catch(error => onError())
  }

  function fetchUserSpecificsForReport(reportId) {
    if (authenticationService.isLoggedIn()) {
      return httpClient.get(
         `/reports/${reportId}/details/user`,
         {headers: {'Authorization': `Bearer ${authenticationService.getToken()}`}})
         .then(response => response.data);
    } else {
      return Promise.reject('NOT_LOGGED_IN');
    }
  }

  async function deleteReport(reportId) {
    const result = await httpClient.delete(
       `/reports/${reportId}`,
       {headers: {'Authorization': `Bearer ${authenticationService.getToken()}`}}
    );
    return !!result;
  }

  async function updateReport(id, report) {
    const result = await httpClient.put(
       `/reports/${id}`,
       report,
       {headers: {'Authorization': `Bearer ${authenticationService.getToken()}`}}
    );
    return result.data;
  }

  async function fetchReportDetails(reportId) {
    return httpClient.get(`/reports/${reportId}/details`,).then(response => response.data)
  }
}