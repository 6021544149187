import React from 'react';
import {Redirect, Route} from "react-router-dom";
import {connect} from "react-redux";

const AdminRoute = ({component: Component, user, ...rest}) => {
  if (!user.user) {
    return null
  } else {
    return (
       <Route
          {...rest}
          render={props => {
            if (user.user.isAdmin) {
              return <Component {...props} />;
            } else {
              return (
                 <Redirect
                    to={{
                      pathname: "/",
                      state: {
                        from: props.location
                      }
                    }}
                 />
              );
            }
          }}
       />
    );
  }
};

const mapStateToProps = state => ({
  user: state.user
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  }
};


export default connect(mapStateToProps, null, mergeProps)(AdminRoute);