import React, {Component} from 'react';
import Input from "../input/Input";
import './select.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import colors from "../../../../style/colors";
import Header3 from "../text/Header3";
import ClickWrapper from "../../../../test/app/components/helperComponents/click/ClickWrapper";

class FullPageSelect extends Component {
  state = {
    active: false,
    selectedItem: {name: ""}
  }

  componentDidMount() {
    if (this.props.initialValue) {
      this.setState({selectedItem: this.props.initialValue})
    } else if (this.props.defaultText) {
      this.setState({selectedItem: {name: this.props.defaultText}})
    }
  }

  activate() {
    this.setState({active: true})
  }

  inactivate() {
    this.setState({active: false})
  }

  selectOption(option) {
    this.props.onOptionSelected(option)
    this.setState({selectedItem: option})
    this.inactivate()
  }

  render() {
    if (!this.state.active) return this.renderPlaceholder()
    else {
      const options = this.props.options.sort((a, b) => a.name.localeCompare(b.name))
      return (
         <div className="full-page-select-overlay">
           <div className="full-page-select-overlay-content">
             <div className="full-page-select-overlay-header">
               <Header3 text={this.props.selectTitle}/>
               <span className="full-page-overlay-close" onClick={() => this.inactivate()}>
               <FontAwesomeIcon
                  icon="times"
                  size="lg"/>
               </span>
             </div>
             <div>
               {options.map(option => {
                 const isCurrentSelected = this.state.selectedItem.name === option.name;
                 return <div
                    onClick={() => this.selectOption(option)}
                    className={isCurrentSelected ? "full-page-select-option full-page-select-option-active" : "full-page-select-option"}
                    key={option.name}>
                   {!!this.props.icon && <FontAwesomeIcon icon={this.props.icon} color={colors.primary50}/>}
                   <span className="full-page-select-option-text">{option.name}</span>
                 </div>
               })}
             </div>
           </div>
         </div>
      );
    }

  }

  renderPlaceholder() {
    return (
       <div>
         <ClickWrapper extraClasses="full-page-select-placeholder-field" onClick={() => this.activate()}>
           <Input
              value={this.state.selectedItem.name}
              readOnly
              type="text"
              label={this.props.label}
              placeholder={this.props.placeholder}
              icon={this.props.icon}
           />
         </ClickWrapper>
       </div>
    );
  }
}

export default FullPageSelect;