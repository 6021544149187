export default function UserService({httpClient, authenticationService}) {
  const clientId = "com.vallaloggen.client.web";

  return {
    getUser,
    createUserByCode,
    deleteUser
  };

  async function getUser() {
    const response = await httpClient.get(
       '/users',
       {headers: {'Authorization': 'Bearer ' + authenticationService.getToken()}}
    );
    return response.data
  }

  function createUserByCode(code, clientToken, user) {
    return httpClient.post('/users', {code, clientToken, clientId, ...user})
       .then(response => response.data.jwt)
       .catch(error => Promise.reject(error.response.data.apiError.type));
  }

  async function deleteUser() {
    const response = await httpClient.delete(
       '/users',
       {headers: {'Authorization': 'Bearer ' + authenticationService.getToken()}}
    );
    return !!response;
  }
}