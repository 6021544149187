import React, {Component} from 'react';
import {connect} from "react-redux";
import {dispatchAction} from "../../../../reduxStore/reduxUtils";
import ReportDetailsComponent from "./ReportDetailsComponent";
import SkeletonLoader from "../../helperComponents/loading/SkeletonLoader";
import UpdateReportComponent from "../create/UpdateReportComponent";

class ReportDetailsProviderComponent extends Component {
  state = {mode: 'VIEW'}

  componentDidMount() {
    const reportId = this.props.match.params.reportId
    dispatchAction(this, this.props.actions.fetchReportDetails(reportId))
  }

  enterUpdateMode() {
    this.setState({mode: 'EDIT'})
  }

  showDetails() {
    this.setState({mode: 'VIEW'})
  }

  render() {
    const reportDetails = this.props.reports.currentReportDetails;
    const snowTypes = this.props.condition.snowTypes;
    const isLoading = !reportDetails // This is a shortcut. If an invalid ID is visited, it will forever show a loader.
    return (
       <div>
         {reportDetails && (this.state.mode === 'VIEW') && <ReportDetailsComponent
            reportDetails={reportDetails}
            snowTypes={snowTypes}
            history={this.props.history}
            maybeUserId={!!this.props.user.user && this.props.user.user.id}
            onRequestUpdateMode={() => this.enterUpdateMode()}
         />}
         {this.state.mode === 'EDIT' &&
         <UpdateReportComponent report={reportDetails} onDone={() => this.showDetails()}/>}

         {isLoading &&
         <SkeletonLoader instructions={['header', 'line', 'line', 'pause32', 'block', 'line', 'line', 'line']}/>}
       </div>
    );
  }
}

const mapStateToProps = state => ({
  reports: state.reports,
  condition: state.condition,
  user: state.user
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    actions: {...stateProps.reports.actions},
    ...dispatchProps,
    ...ownProps
  }
};

export default connect(mapStateToProps, null, mergeProps)(ReportDetailsProviderComponent);