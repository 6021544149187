import React from 'react';
import blueLogo from "../../../../assets/blue-logo.svg";
import Header1 from "../text/Header1";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const VallaloggenAnimation = (props) => {
  return (
     <div className="vallaloggen-animation-container">
       <div className="vallaloggen-animation-endMessage">
         <div>
           <Header1 title={props.endMessage}/>&nbsp;&nbsp;&nbsp;
         </div>
         <FontAwesomeIcon icon="check" size="3x"/>
       </div>
       <div className="vallaloggen-animation-content">
         <img src={blueLogo} className="vallaloggen-animation-logo" alt="Vallaloggen logo"/>
       </div>
     </div>
  );
};

export default VallaloggenAnimation;