import React from 'react';
import {Facebook, Instagram} from "grommet-icons";
import analytics from "../../analytics/analytics";

const ContactMedias = () => {
  function goToFacebook() {
    analytics.facebookClicked();
    window.open('https://www.facebook.com/Vallaloggen-100545368088840/', '_blank');
  }

  function goToInstagram() {
    analytics.instagramClicked();
    window.open('https://www.instagram.com/vallaloggen/', '_blank');
  }

  return (
     <div>
       <div className="contact-media contact-media-email">
         <h4><a href="mailto:info@vallaloggen.se">info@vallaloggen.se</a></h4>
       </div>
       <div className="contact-media-content">
         <div className="contact-media"><Facebook color={"#4267B2"} onClick={() => goToFacebook()}/></div>
         <div className="contact-media"><Instagram color="primary" onClick={() => goToInstagram()}/></div>
       </div>
     </div>
  );
};

export default ContactMedias;