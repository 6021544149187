import React from 'react';
import ReportPreview from "../components/report/reportPreview/ReportPreview";
import Header3 from "../components/helperComponents/text/Header3";
import colors from "../../style/colors";

const ReportsSearchResult = (props) => {
  return (
     <div>
       <div>
         <div>
           <Header3 icon={["far", "thumbs-up"]} iconColor={colors.success50} text="Såhär har andra vallat..."/>
         </div>
         <div>
           {props.results.map(report => <ReportPreview
              key={report.report.id}
              report={report.report}
              onClick={() => props.onReportClicked(report.report)}/>)}
         </div>
       </div>
     </div>
  );
};

export default ReportsSearchResult;