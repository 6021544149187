import React, {Component} from 'react';
import Modal from "../../helperComponents/Modal";
import {connect} from "react-redux";
import OutlinedButton from "../../helperComponents/buttons/OutlinedButton";

class SettingsComponent extends Component {
  state = {
    confirmDeleteVisible: false
  };

  showDeleteUserModal() {
    this.setState({confirmDeleteVisible: true})
  }

  hideDeleteUserModal() {
    this.setState({confirmDeleteVisible: false});
  }

  deleteUser() {
    this.props.dispatch(this.props.actions.deleteUser());
    this.hideDeleteUserModal();
  }

  logOut() {
    this.props.dispatch(this.props.actions.logOut());
    window.location.href = '/'; // Do not need to, but to make the user feel logged out
  }

  componentDidUpdate() {
    if (this.props.user.userDeleted) {
      this.props.history.push('/good-bye');
    }
  }

  render() {
    return (
       <div>
         <div className="user-settings-content">
           <div className="user-settings-items">
             <div className="user-settings-item">
               <OutlinedButton
                  label="Logga ut"
                  type="danger"
                  onClick={() => this.logOut()}
               />
             </div>
             <div>
               <OutlinedButton
                  label="Avsluta konto"
                  type="danger"
                  className="user-settings-item"
                  onClick={() => this.showDeleteUserModal()}/>
             </div>

           </div>

         </div>
         {
           this.state.confirmDeleteVisible &&
           <Modal
              onOk={() => this.deleteUser()}
              onCancel={() => this.hideDeleteUserModal()}
              onClickOutSide={() => this.hideDeleteUserModal()}
           >
             <DeleteInfoComponent/>
           </Modal>
         }
       </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  authentication: state.authentication
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    actions: {...stateProps.user.actions, ...stateProps.authentication.actions},
    ...dispatchProps,
    ...ownProps
  }
};
export default connect(mapStateToProps, null, mergeProps)(SettingsComponent);

const DeleteInfoComponent = () => {
  return (
     <div>
       <div>
         <div>
           <h2>Är du säker på att du vill avsluta ditt konto?</h2>
           <div>
             <p>Ditt konto kommer att tas bort och du kommer inte att kunna återskapa det.</p>
             <strong>OBS!</strong><p>Dina rapporter kommer inte att tas bort, utan kommer att finnas kvar som anonyma
             raporter. Vill du även att dessa ska tas bort måste du själv ta bort dessa manuellt</p>
           </div>
         </div>
       </div>
     </div>
  );
};