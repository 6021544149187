import React, {Component} from 'react';
import Card from "../../components/helperComponents/Card";
import ContactMedias from "../../components/ContactMedias";
import TopMenu from "../../components/TopMenu";

class HelpVallaloggen extends Component {
  render() {
    return (
       <div>
         <TopMenu/>
         <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
           <div style={{width: "80vw"}}>
             <Card>
               <div>
                 <h1>Hjälp vallaloggen</h1>
                 <p>Vallaloggens mål är att göra vallning mindre krånligt genom att samla allt på en plats.
                   <strong> Vill du hjälpa till? </strong></p>
                 <p>Toppen! Här kan du se vad vi behöver hjälp med just nu!</p>
                 <h2>Hur kan jag hjälpa till?</h2>
                 <h3>Bidra med rapport-data</h3>
                 <p>
                   Eftersom rapporter är en viktig del av Vallaloggen är vi alltid intresserade av att lägga till
                   mer rapporter. Har du excel-dokument, anteckningar, facebook-poster eller annat där du dokumenterat
                   hur du vallat får du gärna höra av dig.
                   Vi lägger in dessa rapporter i vårt system och knyter dem till din användare.
                   Ju mer rapporter vi har tillgång till desto mer värde ser andra i att själva bidra, och desto bättre
                   kan vi ge vallarekommendationer.
                 </p>
                 <h3>Jag vet hur Vallaloggen kan bli ännu bättre!</h3>
                 <p>
                   Har du förslag på hur Vallaloggen kan förbättras? En ny funktion, annat utseende och så vidare.
                   Skicka ett mail!
                 </p>
                 <h3>De vallor jag vill använda finns inte</h3>
                 <p>
                   Vallorna som finns i vallaloggen läggs till manuellt och "vallalådan" växer ständigt. Hittar du inte
                   din valla? Hör av dig så lägger vi till den.
                 </p>
               </div>
               <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                 <ContactMedias/>
               </div>
             </Card>
           </div>
         </div>
       </div>
    )
       ;
  }
}

export default HelpVallaloggen;