import React from 'react';
import Button from "./Button";
import './buttons.scss';

const TextButton = (props) => {
  return (
     <Button {...props} className={`vallaloggen-button-text`}/>
  );
};

export default TextButton;