import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import ClientInfoModule from "./modules/clientInfo/ClientInfoModule";
import RootReducer from "./reducers/rootReducer";
import axios from "axios";
import ClientInfoService from "./modules/clientInfo/ClientInfoService";
import AuthenticationModule from "./modules/authentication/AuthenticationModule.js";
import AuthenticationService from "./modules/authentication/AuthenticationService";
import LocalStorageGateway from "./LocalStorageGateway";
import UserModule from "./modules/user/UserModule";
import UserService from "./modules/user/UserService";
import ConditionsModule from './modules/condition/ConditionModule.js'
import ConditionsService from './modules/condition/ConditionsService.js'
import ProductModule from "./modules/products/ProductModule";
import ProductService from "./modules/products/ProductService";
import ReportsModule from "./modules/report/ReportsModule";
import ReportsService from "./modules/report/ReportsService";
import AdminAddDataModule from "../app/admin/AdminAddDataModule";
import AdminAddDataService from "../app/admin/addData/AdminAddDataService";
import Randomizer from "../utils/Randomizer";
import SettingsModule from "./modules/settings/SettingsModule";
import SettingsRepository from "./modules/settings/SettingsRepository";
import UserWaxBoxService from "./modules/products/UserWaxBoxService";

export default function configureStore(initialState = {}) {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;
  const httpClient = axios;

  const localStorageGateway = LocalStorageGateway();

  const clientInfoService = ClientInfoService({httpClient});
  const clientInfoModule = ClientInfoModule({clientInfoService, localStorageGateway});

  const authenticationService = AuthenticationService({httpClient, localStorageGateway});
  const userService = UserService({httpClient, authenticationService});
  const randomizer = Randomizer();
  const authenticationModule = AuthenticationModule({authenticationService, userService, randomizer});
  const userModule = UserModule({authenticationService, userService});

  const conditionsService = ConditionsService({httpClient, authenticationService});
  const conditionsModule = ConditionsModule({conditionsService});

  const waxBoxService = UserWaxBoxService({httpClient, authenticationService});
  const productService = ProductService({httpClient, authenticationService});
  const productModule = ProductModule({productService, waxBoxService});

  const reportsService = ReportsService({httpClient, authenticationService});
  const reportsModule = ReportsModule({reportsService});

  const adminAddDataService = AdminAddDataService({httpClient, authenticationService});
  const adminModule = AdminAddDataModule({adminAddDataService});

  const settingsRepository = SettingsRepository({localStorageGateway});
  const settingsModule = SettingsModule({settingsRepository});

  const rootReducer = RootReducer({
    clientInfoReducer: clientInfoModule.reducer,
    authenticationReducer: authenticationModule.reducer,
    userReducer: userModule.reducer,
    conditionsReducer: conditionsModule.reducer,
    productReducer: productModule.reducer,
    reportsReducer: reportsModule.reducer,
    adminReducer: adminModule.reducer,
    settingsReducer: settingsModule.reducer
  }).reducers;

  return createStore(
     rootReducer,
     initialState,
     applyMiddleware(thunk)
  );
}