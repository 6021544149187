import React from 'react';
import {errorTextByType} from "../../../../text/texts";
import './texts.scss';

const ErrorSpace = (props) => {
  return (
     <div className="error-space">
       {props.errorEnum && <div className="error-space-text">{errorTextByType(props.errorEnum)}</div>}
     </div>
  );
};

export default ErrorSpace;