export const theme = {
  global: {
    font: {
      family: 'Roboto',
      size: '14px'
    },
    colors: {
      primary: '#7D4CDB'
    }
  },
  tab: {
    color: "#444444",
    active: {
      color: '#7D4CDB'
    },
    border: {
      active: {
        color: '#7D4CDB'
      },
      color: "#444444"
    }
  }
};

