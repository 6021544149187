export default function ConditionsService({httpClient, authenticationService}) {

  return {
    fetchSnowTypes
  };

  async function fetchSnowTypes() {
    const response = await httpClient.get('/condition/snow/type');
    return response.data
  }
}