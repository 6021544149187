import React from 'react';
import ListItemCard from "../../helperComponents/lists/ListItemCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import colors from "../../../../style/colors";
import './_reportPreview.scss'

const ReportPreview = (props) => {
  return (
     <ListItemCard onClick={() => props.onClick && props.onClick()}>
       <div className="report-preview-content">
         <div className="report-preview-left">
           <div className="report-preview-icon-container">
             <FontAwesomeIcon icon="circle" color={colors.success05} size="xs"/>
           </div>
           <div className="report-preview-title">{props.report.title}</div>
         </div>
         <div className="report-preview-right">
           {!!props.report.glideRating &&
           <span className="report-preview-glide-indicator report-preview-indicator">G</span>}
           {!!props.report.gripRating &&
           <span className="report-preview-grip-indicator report-preview-indicator">F</span>}
         </div>
       </div>
     </ListItemCard>
  );
};

export default ReportPreview;