import React, {Component} from 'react';
import Page from "../Page";
import MyAccountComponent from "../../components/user/account/MyAccountComponent";

class UserPage extends Component {
  render() {
    return (
       <Page disableNavigationField={true}>
         <MyAccountComponent history={this.props.history}/>
       </Page>
    );
  }
}

export default UserPage;