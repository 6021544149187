import React, {Component} from 'react';
import {connect} from 'react-redux';
import LoginComponent from "../../components/user/login/LoginComponent";
import Page from "../Page";
import Header1 from "../../components/helperComponents/text/Header1";
import InformationField from "../../components/helperComponents/text/PageInformation";


class Loginpage extends Component {

  async errorGoogle(error) {
  }

  success() {
    const maybeRedirect = new URLSearchParams(this.props.location.search).get('redirect');
    const redirectTo = maybeRedirect ? maybeRedirect : '/'
    this.props.history.push(redirectTo);
  }

  render() {
    return (
       <Page disableNavigationField={true}>
         <div className="loginpage-container">
           <div className="loginpage-content">
             <div className="loginpage-instructions-container">
               <Header1 title="Logga in" className="loginpage-header"/>
               <InformationField>
                 <p>{this.getTextByRedirect()}</p>
               </InformationField>
             </div>
             <div className="loginpage-loginOptionsContainer">
               <LoginComponent
                  onSuccess={() => this.success()}
                  onFailure={(error) => this.errorGoogle(error)}/>
             </div>
           </div>
         </div>
       </Page>
    );
  }

  getTextByRedirect() {
    const maybeRedirect = new URLSearchParams(this.props.location.search).get('redirect');
    if (maybeRedirect === '/create/report') {
      return 'Kul att du vill skapa en vallarapport! Först behöver du skapa ett konto. Det gör du snabbt och enkelt här nedan!'
    } else {
      return 'Med ett konto hos vallaloggen kan du enkelt spara vallarapporter och få snabb information om vallor\n' +
         'du äger, på alla dina enheter.'
    }
  }
}

const mapStateToProps = state => ({
  googleClientId: state.clientInfo.googleClientId,
  authentication: state.authentication
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    actions: {...stateProps.authentication.actions},
    ...dispatchProps,
    ...ownProps
  }
};

export default connect(mapStateToProps, null, mergeProps)(Loginpage);
