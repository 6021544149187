import React, {Component} from 'react';
import {connect} from "react-redux";
import Card from "../../components/helperComponents/Card";
import {Button} from "grommet";

class AdminCalculateWaxScores extends Component {
  render() {
    return (
       <div>
         <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "64px"}}>
           <Card>
             <div>
               <Button onClick={() => this.calculate()} label="Synka vallor"/>
             </div>
           </Card>
         </div>

       </div>
    );
  }

  calculate() {
    this.props.dispatch(this.props.actions.calculateWaxScores())
  }
}

const mapStateToProps = state => ({
  admin: state.admin
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    actions: {...stateProps.admin.actions},
    ...dispatchProps,
    ...ownProps
  }
};


export default connect(mapStateToProps, null, mergeProps)(AdminCalculateWaxScores);
