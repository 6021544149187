import React, {Component} from 'react';
import {connect} from "react-redux";
import FullPageSelect from "../helperComponents/select/FullPageSelect";
import TemperatureInput from "../helperComponents/input/TemperatureInput/TemperatureInput";
import {truthyOrZero} from "../../../utils/intUtils";

class ConditionsSelect extends Component {

  state = {
    temperature: '',
    snowType: null
  }

  componentDidMount() {
    if (truthyOrZero(this.props.initialTemperature)) {
      this.setState({temperature: this.props.initialTemperature})
    }
    if (this.props.initialSnowTypeId) {
      this.setState({snowType: this.props.condition.snowTypes.find(s => this.props.initialSnowTypeId === s.id)})
    }
  }

  setTemperature(temperature) {
    // Using a callback here since we need to wait for the state to be set before we can provide the information via state.
    this.setState({temperature}, () => this.provideConditions());
  }

  setSnowType(snowType) {
    // Using a callback here since we need to wait for the state to be set before we can provide the information via state.
    if (snowType.id) {
      this.setState({snowType: {snowType: snowType.snowType, id: snowType.id}}, () => this.provideConditions());
    } else this.setState({snowType: null}, () => this.provideConditions()) // when "alla snötyper is selected
  }

  provideConditions() {
    this.props.onConditionChange({temperature: this.state.temperature, snowType: this.state.snowType});
  }

  render() {
    const snowTypesToUseDuringTest = ["nysnö", "finkornig snö", "grov snö", "isig snö"]
    const displaySnowTypes = this.props.condition.snowTypes.filter(s => snowTypesToUseDuringTest.includes(s.snowType.toLowerCase()))
    const snowTypesItems = displaySnowTypes.map(s => ({name: s.snowType, ...s}))
    this.props.allowDeselectSnowType && snowTypesItems.push({name: "Alla snötyper"})
    const maybeInitialSnowType = snowTypesItems.find(s => s.id === this.props.initialSnowTypeId);
    const temperatureLabel = this.props.temperatureLabel || "Temperatur"
    const snowTypeLabel = this.props.snowTypeLabel || "Snö"
    return (
       <div>
         <div>
           <div className="conditions-select-temperature-field">
             <TemperatureInput
                initialValue={this.props.initialTemperature}
                onTemperatureChange={(temp) => this.setTemperature(temp)}
                placeholder="Ange en temperatur"
                label={temperatureLabel}
             />
           </div>
           <div>
             <FullPageSelect
                selectTitle="Välj en snötyp"
                label={snowTypeLabel}
                icon={"snowflake"}
                options={snowTypesItems}
                placeholder="Ange en snötyp"
                initialValue={maybeInitialSnowType}
                onOptionSelected={snowType => this.setSnowType(snowType)}/>
           </div>
         </div>
       </div>
    );
  }
}

const mapStateToProps = state => ({
  condition: state.condition,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    actions: {...stateProps.condition.actions},
    ...dispatchProps,
    ...ownProps
  }
};


export default connect(mapStateToProps, null, mergeProps)(ConditionsSelect);