import React, {Component, PureComponent} from 'react';
import {Box, CheckBox, Select} from "grommet";

class Option extends PureComponent {
  render() {
    const {text, selected} = this.props;
    return (
       <Box className="multi-select-option" direction="row" gap="small" align="center" pad="xsmall">
         <CheckBox tabIndex="-1" checked={selected} onChange={() => ({})}/>
         {text}
       </Box>
    );
  }
}

// todo remove when report details is re-implemented
class MultiSelect extends Component {
  state = {
    selected: []
  };

  componentDidMount() {
    if (this.props.selected) {
      this.setState({selected: this.props.selected})
    }
  }

  onClose() {
    const selectedItems = this.state.selected.map(selected => this.props.options.find(o => o.text === selected));
    this.props.onSelectedItems(selectedItems)
  }

  render() {
    const {selected} = this.state;
    const options = this.props.options.map(o => o.text);
    return (
       <Select
          multiple
          plain={!this.props.fill}
          dropHeight="medium"
          closeOnChange={false}
          placeholder={this.props.placeholder || "Snötyper"}
          value={selected}
          options={options}
          onClose={() => this.onClose()}
          data-test="multi-select"
          messages={{multiple: selected.join(', ')}}
          onChange={({option}) => {
            const newSelected = [...selected];
            const selectedIndex = newSelected.indexOf(option);
            if (selectedIndex >= 0) {
              newSelected.splice(selectedIndex, 1);
            } else {
              newSelected.push(option);
            }
            this.setState({selected: newSelected});
          }}
       >
         {option => (
            <Option text={option} selected={selected.indexOf(option) >= 0}/>
         )}
       </Select>
    );
  }
}

export default MultiSelect;

// https://codesandbox.io/s/grommet-v2-lot-of-select-options-mfinp