import React from 'react';
import TopMenu from "../../components/TopMenu";
import Card from "../../components/helperComponents/Card";
import ContactMedias from "../../components/ContactMedias";

const ContactAndFeedback = () => {

  return (
     <div>
       <TopMenu/>
       <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
         <div style={{width: "75vw"}}>
           <h1>Kontakt och feedback</h1>
           <Card>
             <div style={{
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
               flexDirection: 'column',
               textAlign: 'center'
             }}>
               <div>
                 <h3 style={{marginBottom: "32px"}}>
                   Har du frågor, funderingar eller förbättringsförslag?
                 </h3>
                 <p style={{marginBottom: "32px"}}>Tveka inte att höra av dig till Vallaloggen. Vi strävar efter att
                   ständig bli bättre, och försöker
                   alltid hjälpa till på bästa sätt.
                 </p>
                 <p>Svara gärna på vår <a href="https://sv.surveymonkey.com/r/VTY2TKZ">Feedback-enkät</a>. Den är helt
                   anonym</p>
               </div>
               <div>
                 <h2>Kontakta vallaloggen </h2>
                 <ContactMedias/>
               </div>
             </div>
           </Card>
         </div>
       </div>
     </div>
  );
};

export default ContactAndFeedback;
