import React from 'react';
import Page from "../Page";
import ProductDetailsProviderComponent from '../../components/product/productDetails/ProductDetailsProviderComponent';

const ProductDetailsPage = (props) => {
  const comingFrom = props.location.state && props.location.state.from;
  const backTextBasedOnLastLocation = {
    SEARCH: "Sök",
    USER_PAGE: "Tillbaka"
  }[comingFrom] || 'Tillbaka'
  return (
     <div>
       <Page hideTopMenu={true} navigationProps={{label: backTextBasedOnLastLocation}}>
         <ProductDetailsProviderComponent {...props}/>
       </Page>
     </div>
  );
};

export default ProductDetailsPage;