import events from '../../actionEvents.js'
import {removeBlanks} from '../../../utils/objectUtils.js';
import analytics from "../../../analytics/analytics";

export default function AuthenticationModule({authenticationService, randomizer, userService}) {

  const baseState = {
    authToken: null,
    isLoggedIn: authenticationService.isLoggedIn && authenticationService.isLoggedIn(),
    loginStatus: '',
    loginErrorMessage: '',
    clientToken: '',
    isFirstTimeUser: false,
    actions: {
      loginUser,
      logOut,
      loginByEmail,
      createUserByCode,
      loginUserByCode,
      setIsFirstTimeUser
    }
  };

  return {
    reducer,
    actions: baseState.actions
  };

  function loginByEmail(email) {
    return async (dispatch) => {
      dispatch(events.setLoginError(getErrorMessageForError('')));
      if (!email.length) {
        return;
      }
      try {
        const clientToken = randomizer.randomString(10);
        const response = await authenticationService.initLoginByEmail(email, clientToken);
        dispatch(events.setClientTokenForUserLogin(clientToken));
        if (response.status === 'INIT_CREATE') {
          dispatch(events.setLoginStatus('INIT_CREATE'));
          analytics.startedToLoginWithEmail("new user started to log in: " + window.location.pathname);
        } else if (response.status === 'USER_EXIST') {
          dispatch(events.setLoginStatus('USER_EXIST'));
          analytics.startedToLoginWithEmail("existing user started to log in: " + window.location.pathname);
        }
      } catch (error) {
        dispatch(events.setLoginError(getErrorMessageForError(error)))
      }
    }
  }

  function loginUser({tokenId}) {
    return async (dispatch, getState) => {
      const response = await authenticationService.loginUser({googleTokenId: tokenId});
      authenticationService.saveToken({token: response.jwt});
      dispatch(events.setLoggedIn(true));
      if (response.didCreate) {
        dispatch(events.setIsFirstTimeUser(true))
      }
      dispatch(getState().user.actions.fetchUser())
    }
  }

  function createUserByCode(code, user) {
    return async (dispatch, getState) => {
      dispatch(events.setLoginError(getErrorMessageForError('')));
      const clientToken = getState().authentication.clientToken;
      await userService.createUserByCode(code, clientToken, removeBlanks(user))
         .then(token => {
           dispatch(events.setLoggedIn(true));
           dispatch(events.setIsFirstTimeUser(true));
           authenticationService.saveToken({token});
           dispatch(getState().user.actions.fetchUser());
           analytics.loggedInWithEmail('Create from path: ' + window.location.pathname)
         })
         .catch(error => {
           if (error === 'INVALID_CODE') {
             dispatch(events.setLoginError(getErrorMessageForError(error)));
             analytics.faildLoginWitEmail("error was: " + getErrorMessageForError(error));
           }
         });
    }
  }

  function loginUserByCode(code, email) {
    return async (dispatch, getState) => {
      dispatch(events.setLoginError(getErrorMessageForError('')));
      const clientToken = getState().authentication.clientToken;
      await authenticationService.loginByCode(code, clientToken, email)
         .then(token => {
           dispatch(events.setLoggedIn(true));
           authenticationService.saveToken({token});
           dispatch(getState().user.actions.fetchUser());
           analytics.loggedInWithEmail('Existing user logged in from path: ' + window.location.pathname)
         })
         .catch(error => {
           if (error === 'INVALID_CODE') {
             dispatch(events.setLoginError(getErrorMessageForError(error)));
             analytics.faildLoginWitEmail("error was: " + getErrorMessageForError(error));
           }
         })
    }
  }

  function logOut() {
    return async (dispatch) => {
      authenticationService.logOut();
      dispatch(events.setLoggedIn(false));
    }
  }

  function setIsFirstTimeUser(is) {
    return async (dispatch) => {
      dispatch(events.setIsFirstTimeUser(is));
    }
  }


  function reducer(state = baseState, action) {
    switch (action.type) {
      case "SUCCESSFUL_LOGIN":
        return {
          ...state,
          authToken: action.authToken
        };
      case 'SET_LOGGED_IN':
        return {
          ...state,
          isLoggedIn: action.loggedIn
        };
      case 'SET_LOGIN_STATUS':
        return {
          ...state,
          loginStatus: action.status
        };
      case 'SET_LOGIN_ERROR':
        return {
          ...state,
          loginErrorMessage: action.errorMessage
        };
      case 'SET_CLIENT_TOKEN':
        return {
          ...state,
          clientToken: action.clientToken
        };
      case 'SET_IS_FIRST_TIME_USER':
        return {
          ...state,
          isFirstTimeUser: action.is
        };
      default:
        return state
    }
  }

  function getErrorMessageForError(error) {
    return {
      'INVALID_EMAIL': 'Inkorrekt epost',
      'INVALID_CODE': 'Koden du angav var felaktig eller ogiltig'
    }[error]
  }

}