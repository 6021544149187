import React, {Component} from 'react';
import UserReports from "../../components/report/user/UserReports";
import Page from "../Page";

class UserReportsPage extends Component {
  render() {
    return (
       <Page>
         <UserReports history={this.props.history}/>
       </Page>
    );
  }
}

export default UserReportsPage;